import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {fromEvent} from 'rxjs';

import {Q9ListStore} from '../../../q9-list/store.service';
import {RefModelsSharedDaoService} from '../../../../services/referencemodels/ref-models-shared-dao.service';

@Component({
  selector: 'show-all-ref-model-users',
  templateUrl: './show-all-ref-model-users.component.html',
  styleUrls: ['./show-all-ref-model-users.component.scss']
})
export class ShowAllRefModelUsersComponent implements OnInit {
  @ViewChild('filterTitle', {static: true}) filterTitle: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  dataSource = new MatTableDataSource();

  readonly MAX_USERS_COUNT = 10;

  refModelName: string;
  baseQueryParams = {
    skip: 0,
    limit: this.MAX_USERS_COUNT,
    search: '',
    refModelRole: ''
  };

  isLoading = false;
  total: number;
  users: any[];

  scrollbarConfig = {
    suppressScrollX: true
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private refOrgSharedDaoService: RefModelsSharedDaoService,
    private store: Q9ListStore,
    public dialogRef: MatDialogRef<ShowAllRefModelUsersComponent>
  ) {}

  ngOnInit() {
    this.refModelName = this.data.row.name;

    this.initFilter();
    this.initPagination();
    this.loadRefModelUsers();
  }

  initFilter() {
    fromEvent(this.filterTitle.nativeElement, 'keyup')
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filter(() => {
          const userInput = this.filterTitle.nativeElement.value.trim();
          return userInput === '' || userInput.length > 1;
        }),
        tap(() => this.loadRefModelUsers(true))
      )
      .subscribe();
  }

  initPagination() {
    this.paginator.page
      .pipe(filter(pageChanged => !!pageChanged))
      .subscribe(() => this.loadRefModelUsers());
  }

  private loadRefModelUsers(resetPagination?: boolean) {
    if (resetPagination) {
      this.paginator.pageIndex = 0;
    }

    const params = Object.assign({}, this.baseQueryParams);
    params.limit = this.paginator.pageSize || this.baseQueryParams.limit;
    params.skip = this.paginator.pageIndex * (this.paginator.pageSize || this.baseQueryParams.skip);
    params.search = this.filterTitle.nativeElement.value.trim();
    params.refModelRole = this.data.refModelRole;

    this.isLoading = true;

    return this.refOrgSharedDaoService.refModelUsers(this.data.row, params).subscribe(
      (response: {total: any; data: any[]}) => {
        /** case when we don't have any more items on page after item was removed*/
        if (!response.data.length && response.total[params.refModelRole]) {
          const page = this.paginator.pageIndex;
          this.paginator.pageIndex = page - 1 < 1 ? 0 : page - 1;
          return this.loadRefModelUsers();
        }

        this.total = response.total[params.refModelRole];
        this.users = response.data;
        this.isLoading = false;
      },
      () => (this.isLoading = false)
    );
  }

  refModelUserCallBack(evt: {data: any; action?: string; user?: any}) {
    switch (evt && evt.action) {
      case 'remove-ref-model':
        this.store.removeRow(evt.data.id, null);
        this.dialogRef.close(evt.data);
        break;
      case 'change-role':
      case 'remove-user':
      default:
        this.loadRefModelUsers();
    }
  }
}
