<div mat-dialog-title>
  <!--  <div-->
  <!--    class="dialog-title">{{ (data.type === 'completed' ? 'TRAININGS.USERS_COMPLETED_TASKS_TITLE' : 'TRAININGS.USERS_NOT_COMPLETED_TASKS_TITLE') | translate}}</div>-->
  <div class="dialog-title">{{ 'Users progress statistics' | translate }}</div>
  <button mat-button mat-dialog-close="close" tabindex="3" class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="filter-header">
    <div class="col-9 no-padding">
      <mat-form-field
        class="q9-form-field q9-full-width q9-without-label q9-form-field-filter middle"
        floatLabel="always"
        appearance="outline">
        <input
          #filterByUser
          matInput
          cdkFocusInitial
          autocomplete="off"
          [placeholder]="'TRAININGS.USERS_STATISTICS_FILTER_PLACEHOLDER' | translate" />
      </mat-form-field>
    </div>

    <div class="col-3">
      <mat-form-field
        class="q9-form-field q9-full-width q9-without-label q9-form-field-filter"
        floatLabel="never"
        appearance="outline">
        <mat-select panelClass="q9-select" [formControl]="trainingStatusControl">
          <mat-option
            class="q9-select-option"
            *ngFor="let status of trainingStatuses"
            [value]="status.value"
            >{{ status.label }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="content">
    <q9-list-light [dataSource]="dataSource" [config]="listConfig">
      <div class="no-items" *ngIf="!dataSource.data.length">
        <img src="./assets/images/path.png" />
        <h3>{{ 'TRAININGS.NO_ITEMS_MESSAGE' | translate }}</h3>
      </div>
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </q9-list-light>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" class="q9-btn" type="button" (click)="exportToCsv()">
    {{ 'TRAININGS.EXPORT_TO_CSV_BTN' | translate }}
  </button>
  <button
    mat-button
    color="primary"
    class="q9-btn q9-btn-border"
    tabindex="1"
    mat-dialog-close="close">
    {{ 'TRAININGS.CLOSE_BTN' | translate }}
  </button>
</mat-dialog-actions>
