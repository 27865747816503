<div mat-dialog-title>
  <div class="dialog-title">{{ 'REFERENCE_MODELS.GDPR_LEGAL_BASIS_STAT_DONUT' | translate }}</div>
  <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<mat-dialog-content class="dialog-content">
  <div class="chart-filter-wrapper" [hidden]="purposeList.length === 1">
    <label class="q9-label">{{ 'REFERENCE_MODELS.FILTER_BY_PURPOSE' | translate }}</label>

    <mat-form-field
      class="q9-form-field q9-hide-label filter-purpose-select q9-without-label"
      [floatLabel]="'never'"
      appearance="outline"
    >
      <mat-select panelClass="q9-select" [disabled]="isLoading" [value]="currentChartPurpose.title">
        <mat-option
          *ngFor="let purpose of purposeList"
          class="q9-select-option"
          (click)="onChangeChartPurpose(purpose)"
          [value]="purpose.title"
          >{{ purpose.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="empty-msg" [hidden]="gdprStatData.allGdprNodes">
    {{ 'REFERENCE_MODELS.NO_LEGAL_BASIS_STAT_DATA' | translate }}
  </div>

  <div class="content-wrapper" [hidden]="!gdprStatData.allGdprNodes">
    <div class="gdpr-donut-chart">
      <google-chart (chartReady)="chartReady = true" [data]="gdprStatusChartConfig"></google-chart>
      <mat-spinner *ngIf="!chartReady"> </mat-spinner>
    </div>

    <div class="gdpr-stat-block">
      <table>
        <tr *ngFor="let object of gdprLegalBasisStates | keys">
          <td>
            <span
              class="color-dot"
              [ngStyle]="{'background-color': gdprLegalBasisStates[object.key].color}"
            ></span>
            <span class="legend-state-name">{{ gdprLegalBasisStates[object.key].label }}</span>
          </td>
          <td>
            {{ (gdprStatData[object.key] / gdprStatData.allGdprNodes) * 100 | number: '1.0-1' }}%
          </td>
          <td>
            {{ gdprStatData[object.key] }}
            <span *ngIf="gdprStatData[object.key] == '1'">field</span>
            <span *ngIf="gdprStatData[object.key] != '1'">fields</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" class="q9-btn q9-btn-border" tabindex="1" mat-dialog-close>
    {{ 'GENERAL.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
