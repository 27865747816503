import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {QueryFn} from '@angular/fire/compat/firestore/interfaces';
import {iif, Observable, of, throwError} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FireStoreService {
  constructor(private fs: AngularFirestore) {}

  /**
   * crud documents
   */

  createDocument(path, data, customId?) {
    if (customId) {
      return this.fs.doc(`${path}/${customId}`).set(data);
    } else {
      return this.fs.collection(path).add(data);
    }
  }

  readDocument(path) {
    return this.fs.doc(path).get();
  }

  updateDocument(path, update): Promise<void> {
    return this.fs.doc(path).update(update);
  }

  deleteRecord(path): Promise<void> {
    return this.fs.doc(path).delete();
  }

  batch() {
    return this.fs.firestore.batch();
  }

  /**
   * querying collections
   */

  findAll<T = any>(path, queryFn?: QueryFn) {
    return this.fs.collection<T>(path, queryFn);
  }

  valueChanges(path): Observable<any> {
    return this.fs.doc(path).valueChanges();
  }

  getRef<T = any>(path: string) {
    const ref = this.fs.doc<T>(path);

    return ref
      .get({source: 'server'})
      .pipe(switchMap(doc => iif(() => doc.exists, of(ref), throwError({notFound: true}))));
  }
}
