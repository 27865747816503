<div class="request-edit-rights">
  <div mat-dialog-title>
    <div class="dialog-title">
      {{ entityType + '.REQUEST_RIGHTS_TITLE' | translate }}
      <span class="q9-modal-subtitle"
        >{{ entityType + '.NAME' | translate: {refModelType: defineRefModelType()} }} "{{ entityName
        }}"</span
      >
    </div>

    <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div class="q9-modal-error" *ngIf="error">
    <div class="text">{{ error }}</div>
  </div>

  <form
    autocomplete="off"
    novalidate
    [formGroup]="requestEditRightsForm"
    (ngSubmit)="requestEditRights()">
    <mat-dialog-content class="dialog-content">
      <div class="loading" *ngIf="inProgress && !error">
        <mat-spinner></mat-spinner>
      </div>

      <div class="content">
        <p
          >{{ entityType + '.REQUEST_RIGHTS_DESCRIPTION' | translate: {refModelType:
          defineRefModelType()} }}</p
        >
        <p>{{ entityType + '.REQUEST_RIGHTS_PROVIDE_REASON_LABEL' | translate }}:</p>

        <div class="q9-modal-form-group">
          <mat-form-field
            class="q9-form-field q9-full-width"
            [floatLabel]="'always'"
            appearance="outline">
            <mat-label>{{ entityType + '.INVITE_CANDIDATE_MESSAGE' | translate }}</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="10"
              [placeholder]="entityType + '.REQUEST_RIGHTS_MODAL_MESSAGE_PH' | translate"
              formControlName="message">
            </textarea>
            <mat-error
              *ngIf="
                requestEditRightsForm.get('message').touched &&
                requestEditRightsForm.get('message').hasError('maxlength')
              ">
              {{ 'TEAMS.INVITE_MODAL_MESSAGE_ERROR_MAX' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button color="primary" class="q9-btn q9-btn-border" tabindex="1" mat-dialog-close>
        {{ 'GENERAL.CANCEL' | translate }}
      </button>
      <button
        mat-raised-button
        class="q9-btn"
        color="primary"
        [disabled]="inProgress"
        tabindex="2"
        cdkFocusInitial>
        {{ 'MAP.SEND_BTN' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
