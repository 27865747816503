import {distinctUntilChanged} from 'rxjs/operators';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {StoreSubjectName, StoreSubscriptionName} from './store-interfaces';
import {Injectable} from '@angular/core';
import {Layout} from 'src/app/_shared/models/layout.interface';
import {DiagramVersion} from 'src/app/_shared/models/diagram-version.interface';
import {getLayout} from './utils/layout';

@Injectable({
  providedIn: 'root'
})
export class Q9ListStore {
  private storeSubject = new BehaviorSubject<any>(null);
  private store$ = this.storeSubject.asObservable().pipe(distinctUntilChanged());

  private responseSubject = new BehaviorSubject<any>(null);
  private response$ = this.responseSubject.asObservable().pipe(distinctUntilChanged());

  private linkClickSubject = new BehaviorSubject<any>(null);
  private linkClick$ = this.linkClickSubject.asObservable().pipe(distinctUntilChanged());

  private rowContentClickSubject = new BehaviorSubject<any>(null);
  private rowContentClick$ = this.rowContentClickSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private propertyUpdatedSubscription = new BehaviorSubject<any>(null);
  public propertyUpdate$ = this.propertyUpdatedSubscription.asObservable();

  private rowUpdatedSubscription = new BehaviorSubject<any>(null);
  public rowUpdate$ = this.rowUpdatedSubscription.asObservable();

  private rowToRemoveSubject = new BehaviorSubject<any>(null);
  private rowToRemove$ = this.rowToRemoveSubject.asObservable();

  private createNewRowSubject = new BehaviorSubject<any>(null);
  private createNewRow$ = this.createNewRowSubject.asObservable();

  private afterCreateHookSubject = new BehaviorSubject<any>({afterCreateHook: false});
  private afterCreateHook$ = this.afterCreateHookSubject.asObservable();

  private filterSubject = new BehaviorSubject<any>(null);
  private filter$ = this.filterSubject.asObservable();

  private listLoadedSubject = new Subject<boolean>();
  private listLoaded$ = this.listLoadedSubject.asObservable();

  private trainingItemsSubject = new BehaviorSubject<any>(null);
  private trainingItems$ = this.filterSubject.asObservable();

  private layoutSubject = new BehaviorSubject<Layout>(getLayout());
  private layout$ = this.layoutSubject.asObservable();

  private mapDeletedSubject = new Subject<DiagramVersion>();
  private mapDeleted$ = this.mapDeletedSubject.asObservable();

  private mapRenamedSubject = new Subject<DiagramVersion>();
  private mapRenamed$ = this.mapRenamedSubject.asObservable();

  private mapOwnerChangedSubject = new Subject<DiagramVersion>();
  private mapOwnerChanged$ = this.mapOwnerChangedSubject.asObservable();

  private accessLevelChangedSubject = new Subject<DiagramVersion>();
  private accessLevelChanged$ = this.accessLevelChangedSubject.asObservable();

  private tagsUpdatedSubject = new Subject<DiagramVersion>();
  private tagsUpdated$ = this.tagsUpdatedSubject.asObservable();

  private gridItemImportSubject = new Subject<DiagramVersion>();
  private gridItemImport$ = this.gridItemImportSubject.asObservable();

  set(name: StoreSubjectName, stateValue: any) {
    this[`${name}Subject`].next(stateValue);
  }

  setLinkClick(data: any) {
    this.linkClickSubject.next(data);
  }

  setRowContentClick(data: any, header: string) {
    this.rowContentClickSubject.next({data, header});
  }

  getLinkClick() {
    return this.linkClick$;
  }

  getValue(name: StoreSubjectName = 'store') {
    return this[`${name}Subject`].value;
  }

  getSubscription<T = any>(name: StoreSubscriptionName = 'store$'): Observable<T> {
    return this[name] as Observable<T>;
  }

  setPropertyUpdate(id, key, value) {
    this.propertyUpdatedSubscription.next({id, key, value});
  }

  setRowUpdate(id, props) {
    this.rowUpdatedSubscription.next({id, props});
  }

  replaceRow(id, data?) {
    this.rowToRemoveSubject.next({oldRow: id, newRow: data});
    this.set('store', data);
  }

  createNewRow(item: any) {
    this.createNewRowSubject.next(item);
  }

  removeRow(oldRow = null, newRow = null) {
    this.rowToRemoveSubject.next({oldRow, newRow});
  }

  destroy() {
    this.linkClickSubject.next(null);
    this.storeSubject.next(null);
    this.responseSubject.next(null);
    this.createNewRowSubject.next(null);
    this.rowToRemoveSubject.next(null);
    this.filterSubject.next(null);
    this.rowContentClickSubject.next(null);
    this.trainingItemsSubject.next(null);
  }

  select<T>(name: string): Observable<T> {
    return this[`${name}$`];
  }
}
