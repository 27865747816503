import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {getCurrentPath} from './util/routing.util';
import {get} from 'lodash';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const propertyPath = 'data.teamIndex';
    const getTeamIndex = route => get(route, propertyPath);
    const sameTeam = getTeamIndex(future) === getTeamIndex(curr);
    const onTeamPage =
      getCurrentPath(future).includes('teams') && getCurrentPath(curr).includes('teams');

    return future.routeConfig === curr.routeConfig && (sameTeam || onTeamPage);
  }
}
