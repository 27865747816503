<div class="alert-dialog">
  <mat-dialog-content>
    <h3 class="error-heading">{{ data.title || 'GENERAL.ERROR' | translate }}</h3>

    <p class="error-message">{{ data.text | translate }}</p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="q9-btn" tabindex="2" [mat-dialog-close]="data" cdkFocusInitial>
      {{ (data.confirm | translate) || 'Ok' }}
    </button>
  </mat-dialog-actions>
</div>
