export enum CloudType {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export enum CloudName {
  ELEMENTS = 'elements',
  US = 'us',
  CISCO = 'cisco'
}
