import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';

import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CloudType} from '../../util/cloud-type.enum';

@Injectable({
  providedIn: 'root'
})
export class IsPublicCloudGuard {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const canActivate = environment.CLOUD_TYPE !== CloudType.PRIVATE;

    return canActivate || (this.router.navigate(['']) && false);
  }
}
