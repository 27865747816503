import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {NavigationEnd, NavigationError, Router} from '@angular/router';
import {Q9GainSightService} from '@q9elements/ui-core';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {environment} from '../environments/environment';
import {SharedStoreService} from './_shared/services/shared-store/shared-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public version: string = environment.version;

  sectionLoader$ = this.sharedStore
    .getSubscription('loader$')
    .pipe(distinctUntilChanged(), debounceTime(100));

  constructor(
    private icons: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private sharedStore: SharedStoreService,
    private q9GainSightService: Q9GainSightService
  ) {
    console.log(`Application version: ${this.version}`);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.dialog.closeAll();
      }

      if (event instanceof NavigationError) {
        switch (event.error) {
          case 'unauthorized':
            const composeQueryParams = (str: string) => {
              const query = str && str.split('?')[1];
              return !query
                ? null
                : JSON.parse(
                    '{"' +
                      decodeURI(query)
                        .replace(/"/g, '\\"')
                        .replace(/&/g, '","')
                        .replace(/=/g, '" : "') +
                      '"}'
                  );
            };
            const queryParams = composeQueryParams(event.url);
            this.router.navigate(['signin'], {queryParams: queryParams});
            break;
          case 'not_verified':
            this.router.navigate(['signup-success']);
            break;
          case 'no_password':
            this.router.navigate(['account-accept']);
            break;
          case 'active':
            this.router.navigate(['t']);
            break;
          default:
            this.router.navigate(['signin']);
        }
      }
    });
  }

  ngOnInit() {
    this.icon('trial-expired', '../assets/icons/trial-expired.svg');
    this.icon('logo', '../assets/icons/elements_logo_new.svg');
    this.icon('search', '../assets/icons/action_icons/icon_search.svg');
    this.icon('loader-logo', '../assets/icons/elements_logo.svg');
    this.icon('tag-faces', '../assets/icons/tag_faces.svg');
    this.icon('share', '../assets/icons/icon-share.svg');
    this.icon('privacy', '../assets/icons/icon-privacy.svg');
    this.icon('settings-rsb', '../assets/icons/icon-settings-rsb.svg');
    this.icon('map-management', '../assets/icons/icon-map-management.svg');
    this.icon('conflicts', '../assets/icons/icon_danger.svg');
    this.icon('salesforce_partner_icon', '../assets/icons/devops-center.svg');
    this.icon('copado_partner_icon', '../assets/icons/copado-devops.svg');

    // Action icons
    this.icon('close', '../assets/icons/action_icons/icon_close.svg');
    this.icon('ideas', '../assets/icons/action_icons/icon_lightbulb.svg');
    this.icon('notifications', '../assets/icons/action_icons/icon_notifications.svg');
    this.icon('visible', '../assets/icons/action_icons/icon_visible.svg');
    this.icon('hidden', '../assets/icons/action_icons/icon_hidden.svg');
    this.icon('enterprise', '../assets/icons/action_icons/icon_enterprise.svg');
    this.icon('additional-action', '../assets/icons/action_icons/icon_details_vertical.svg');
    this.icon('icon-info', '../assets/icons/action_icons/icon_info.svg');
    this.icon('reports', '../assets/icons/action_icons/icon_reporting.svg');
    this.icon('icon-pencil', '../assets/icons/action_icons/icon_pencil.svg');
    this.icon('icon-delete', '../assets/icons/action_icons/icon_delete.svg');
    this.icon('icon-more-horizontal', '../assets/icons/icon-more.svg');
    this.icon('icon-settings', '../assets/icons/icon-settings.svg');
    this.icon('icon-download', '../assets/icons/action_icons/icon_download.svg');
    this.icon('low-conflict', '../assets/icons/action_icons/icon_conflict_low.svg');
    this.icon('high-conflict', '../assets/icons/action_icons/icon_conflict_high.svg');
    this.icon('icon-information', '../assets/icons/action_icons/icon-information.svg');

    // Item icons
    this.icon('jira-icon', '../assets/icons/jira.svg');
    this.icon('csv-file', '../assets/icons/csv-file.svg');
    this.icon('zip-file', '../assets/icons/zip-file.svg');
    this.icon('trash', '../assets/icons/trash.svg');
    this.icon('forbidden', '../assets/icons/forbidden.svg');
    this.icon('follow-star', '../assets/icons/follow_star_filled.svg');

    this.icon('refresh', '../assets/icons/action_icons/icon-refresh-two-arrows.svg');
    this.icon('question', '../assets/icons/action_icons/icon_help.svg');

    // Sidenav Icons
    this.icon('icon-element-home', '../assets/icons/action_icons/icon_home.svg');
    this.icon('icon_elements', '../assets/icons/icon_elements.svg');
    this.icon('q9-requirements', '../assets/icons/q9-requirements.svg');
    this.icon('q9-url-library', '../assets/icons/q9-url-library.svg');
    this.icon('q9-releases', '../assets/icons/q9-releases.svg');
    this.icon('q9-maps', '../assets/icons/q9-maps.svg');
    this.icon('q9-architecture', '../assets/icons/q9-architecture.svg');
    this.icon('q9-ref-models', '../assets/icons/q9-ref-models.svg');
    this.icon('q9-sf', '../assets/icons/q9-sf.svg');
    this.icon('catalyst-app', '../assets/icons/catalyst-app.svg');
    this.icon('catalyst-big-logo', '../assets/icons/elements_catalyst_big_logo.svg');

    // Attachments
    this.icon('link', '../assets/icons/action_icons/icon_URL.svg');
    this.icon('note', '../assets/icons/action_icons/icon_notes.svg');
    this.icon('image', '../assets/icons/action_icons/icon_image.svg');
    this.icon('datatable', '../assets/icons/action_icons/icon_data-table.svg');
    this.icon('activity', '../assets/icons/action_icons/icon_activity_box.svg');
    this.icon('refModelNode', '../assets/icons/action_icons/icon_rmn.svg');
    this.icon('orgModelNode', '../assets/icons/action_icons/icon_sfn.svg');
    this.icon('datepicker_toggle', '../assets/icons/mat-datepicker-toggle.svg');
    this.icon('sf-icon', '../assets/icons/action_icons/icon_go-to-link.svg');
    this.icon('external-url', '../assets/icons/action_icons/icon_external_url.svg');
    this.icon('icon_expand', '../assets/icons/action_icons/icon_expand.svg');
    this.icon('requirement', '../assets/icons/leftbar_navigation_icons/icon_nav_requirements.svg');
    this.icon('arrow-left', '../assets/icons/action_icons/icon_arrow_left.svg');
    this.icon('fullscreen', '../assets/icons/action_icons/icon_fullscreen.svg');
    this.icon('fullscreen-close', '../assets/icons/action_icons/icon_fullscreen_close.svg');
    this.icon('packages-icon', '../assets/icons/action_icons/icon_package.svg');
    this.icon('unlink', '../assets/icons/action_icons/icon_unlink.svg');
    this.icon('fullscreen', '../assets/icons/action_icons/icon_fullscreen.svg');
    this.icon('close-fullscreen', '../assets/icons/action_icons/icon_fullscreen_close.svg');

    // Dashboard cards
    this.icon('q9-comment-icon', '../assets/icons/q9-comment-icon.svg');
    this.icon('q9-edit-icon', '../assets/icons/q9-edit-icon.svg');

    // Tasks notifications
    this.icon('q9-training-notification-icon', '../assets/icons/tasks-notification.svg');
    this.icon('q9-training-notification-item-icon', '../assets/icons/task-icon.svg');

    // Task list
    this.icon('arrow-top', '../assets/icons/icon_arrow_top.svg');
    this.icon('arrow-right', '../assets/icons/icon_arrow_right.svg');
    this.icon('arrow-bottom', '../assets/icons/icon_arrow_down.svg');
    this.icon('checked', '../assets/icons/checked.svg');
    this.icon('declined', '../assets/icons/declined.svg');
    this.icon('authorisation-task', '../assets/icons/authorisation-process.svg');
    this.icon('icon-chart', '../assets/icons/icon_chart.svg');

    // View Modes
    this.icon('q9-list-mode', '../assets/icons/list-mode.svg');
    this.icon('q9-grid-mode', '../assets/icons/grid-mode.svg');
    this.icon('q9-view', '../assets/icons/icon-view.svg');

    this.icons.addSvgIconSet(
      this.sanitizer.bypassSecurityTrustResourceUrl('../assets/icons/action_icons/rsb_icon_set.svg')
    );

    this.q9GainSightService.loadScript(environment.PX_KEY);
  }

  private icon(name, path) {
    this.icons.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
