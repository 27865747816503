import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {LoaderName} from './shared-store.interface';
import {ColumnConfig} from '../../components/q9-grid/models/q9-grid.interface';

@Injectable({
  providedIn: 'root'
})
export class SharedStoreService {
  constructor() {}

  private loaderSubject = new BehaviorSubject<any>(false);
  private loader$ = this.loaderSubject.asObservable();

  private gridLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  gridLoading$: Observable<boolean> = this.gridLoadingSubject.asObservable();

  private notificationSubject = new BehaviorSubject<any>(null);
  private notification$ = this.notificationSubject.asObservable();
  private silent: boolean;

  set(name: LoaderName, stateValue: any, silent?: boolean) {
    if (silent !== undefined) {
      this.silent = silent;
    }
    if (this.silent) {
      return;
    }
    this[`${name}Subject`].next(stateValue);
  }

  getSubscription(name: string): Observable<any> {
    return this[name];
  }

  get isLoading() {
    return this.loaderSubject.value;
  }
}
