import {CommonModule} from '@angular/common';
import {Component, Inject, inject, Injectable, OnInit} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';

import {EntityType, RequestEditRightsService} from './data-access';
import {DAO_MAP, ENTITY_TYPES} from './utils/constants';

@Component({
  standalone: true,
  selector: 'request-edit-rights',
  templateUrl: './request-edit-rights.component.html',
  styleUrls: ['./request-edit-rights.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule
  ]
})
export class RequestEditRightsComponent implements OnInit {
  inProgress: boolean;
  error: string | null;
  entityName: string;
  entityType: EntityType;

  requestEditRightsForm: UntypedFormGroup;
  daoService: RequestEditRightsService;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<RequestEditRightsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.daoService = inject(DAO_MAP[data.entityType]);
  }

  ngOnInit() {
    this.entityType = this.data.entityType;
    this.entityName = this.data.row.name;

    this.requestEditRightsForm = this.formBuilder.group({
      message: ['', Validators.maxLength(5000)]
    });
  }

  requestEditRights() {
    if (!this.daoService || this.inProgress || this.requestEditRightsForm.invalid) {
      return;
    }

    this.inProgress = true;

    const entityId = this.data.row.id;
    const message = this.requestEditRightsForm.get('message').value;

    this.daoService.requestEditRights(entityId, message).subscribe({
      next: value => this.dialogRef.close(value),
      error: err => {
        this.error = typeof err.error === 'object' ? err.error?.error : err.error;
        this.inProgress = false;
      }
    });
  }

  defineRefModelType(): string {
    return this.data.entityType === ENTITY_TYPES.REFERENCE_MODELS
      ? this.data.row.external
        ? 'Org Model'
        : 'Ref Model'
      : '';
  }
}
