<div mat-dialog-title>
  <div class="dialog-title">{{ 'GENERAL.SF_ROLE_TITLE' | translate }}</div>
</div>

<form name="sfForm" [formGroup]="sfForm" (submit)="submitSfRole()">
  <mat-dialog-content>
    <p class="q9-section-data pb-18">{{ 'GENERAL.SF_ROLE_DESCRIPTION' | translate }}</p>
    <mat-form-field
      class="q9-form-field q9-full-width"
      color="primary"
      [floatLabel]="'always'"
      appearance="outline"
    >
      <mat-label>{{ 'SIGNUP.ROLE' | translate }}</mat-label>
      <mat-select
        class="q9-select"
        formControlName="sfRole"
        [errorStateMatcher]="errorStateMatcher"
        [placeholder]="'SIGNUP.ROLE_PH' | translate"
        (selectionChange)="sfRoleChanged()"
      >
        <mat-option class="q9-select-option" *ngFor="let item of sfRoles" [value]="item.key"
          >{{ item.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p>
      {{ 'GENERAL.SF_THX_LATTER' | translate }}
      <a
        href="https://support.elements.cloud/elements-overview-registering-and-getting-going/updating-profile-setting-up-and-changing-the-password"
        target="_blank"
      >
        Updating profile setting up and changing the password
      </a>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      class="q9-btn"
      color="primary"
      type="submit"
      tabindex="1"
      [disabled]="submitDisabled"
    >
      {{ 'GENERAL.SF_SBT_BTN' | translate }}
    </button>
  </mat-dialog-actions>
</form>
