import {RefModelsSharedDaoService} from '../../../../../_shared/services/referencemodels/ref-models-shared-dao.service';
import {MapsDaoService} from '../../../services/maps-dao.service';

export const ENTITY_TYPES = {
  MAP: 'MAP',
  REFERENCE_MODELS: 'REFERENCE_MODELS'
} as const;

export const DAO_MAP = {
  MAP: MapsDaoService,
  REFERENCE_MODELS: RefModelsSharedDaoService
} as const;
