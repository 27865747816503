import {TemplateRef} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {CustomField} from '@q9elements/ui-core';
import {SortDescriptor} from '@progress/kendo-data-query';
import {CellClickEvent, SaveEvent} from '@progress/kendo-angular-grid';
import {AnyPropertiesObject} from './any-properties-object';

export interface CanEditPipeParams {
  gridRow?: any;
  type: 'canEdit' | 'canEditControl' | 'canCreate' | 'canEditRow' | 'canEditStatus';
  column?: ColumnConfig;
  isNew?: boolean;
}

// same stuff as on backend, but with ok namings
export enum GridEditTemplate {
  text = 'text',
  dropdown = 'list',
  textarea = 'longtext',
  numeric = 'number',
  multiselect = 'taglist',
  datepicker = 'datetime',
  autocomplete = 'autocomplete',
  resourceNgSelect = 'resourceNgSelect',
  feedbackMessage = 'feedbackMessage'
}

export enum GridColumnOrder {
  beforeCustomFields = -1,
  afterCustomFields = 1
}

export enum CloseGridRowReason {
  lockTimeout = 'lockTimeout',
  backgroundSave = 'backgroundSave',
  saveRow = 'saveRow'
}

export enum WaitForRowSave {
  enabled = 'enabled',
  disabled = 'disabled',
  rejected = 'rejected'
}

export interface CustomGridConfig extends Partial<AnyPropertiesObject> {
  noItemsText: string;
  idField?: 'reqId' | 'storyId';
  sortDefaults: Array<SortDescriptor>;
}

export interface EditableGridConfig extends GridConfig {
  checkForClosedStatus?: ({gridRow, payload, columns}: CheckStatusArgs) => Promise<boolean>;
}

export interface GridConfig extends CustomGridConfig {
  paginationDefaults: {
    pageSize: number;
    pageSizeOptions: number[];
  };
  getGridData: (params?: any) => Observable<GridDataMergedResponse>;
  addGridRow?: (params: any) => Observable<any>;
  getGridRow?: (id: number | string) => Observable<any>;
  updateGridRow?: (params?: any) => Observable<any>;
  getCustomFields?: () => Observable<any[]>;
  setLockPerRow?: (id: string) => Promise<any>;
  importFromCSV?: () => Subscription | boolean;
  importFromJira?: () => Subscription | boolean;
  exportToCSV?: Function;
  exportToExcel?: Function;
  openContextMenu?: (cellClick: CellClickEvent) => void;
  addRowInModal?: () => Observable<any>;
  editRowInModal?: (item) => Observable<any>;
  changeState?(item): Observable<any>;
  bulkUpdate?: (items) => void;
  bulkDelete?: (items) => void;
  bulkUpdateStatus?: (items) => void;
  bulkSendToJira?: (items) => void;
  columns: ColumnConfig[];
  enabledMultiselect: boolean;
  customFields?: CustomField[];
  itemType?: string;
}

export interface CheckStatusArgs {
  gridRow?: any;
  payload: any;
  columns: ColumnConfig[];
}

export type GridCellData = 'text' | 'numeric' | 'boolean' | 'date';

export interface GridDataMergedResponse {
  gridData: GridDataResponse;
  customFields?: any[];
}

export interface GridDataResponse {
  items: any[];
  total: number;
}

export interface ColumnConfig {
  field?: string;
  controlName: string;
  fieldPath?: string;
  title: string;
  width?: number;
  locked?: boolean;
  filterType?: GridCellData;
  placeholder?: string;
  transform?: Function; // for the sake of consistency, although I'd name sth like getHtmlTemplate
  editTemplate: GridEditTemplate;
  isCustomField?: boolean;
  order?: GridColumnOrder | number;
  initialSortDirection?: 'asc' | 'desc';
  sortDisabled: boolean;
  hidden?: boolean;
  headerWidthMultiplier?: number;
  hiddenInExportExcel?: boolean;
  excelExportTransform?: Function;
  customFilterConfig?: {
    getFirestoreValue: Function;
    getFilterValue: Function;
    filterOperators: {value: string; operator: string}[];
  };
}

export interface EditableColumnConfig extends ColumnConfig {
  editable?: boolean;
  dropdownOptions?: any[];
  defaultValue?: any;
  dropdownValueField?: string;
  loadOptionsImmediately?: boolean;
  validators?: any;
  error?: {
    message: string;
    interpolateParams?: any;
  };
  controlOptionsEndPoint?: (search?: string) => Observable<any[]>;
}

export interface ExtendedSaveEvent extends SaveEvent {
  backgroundSave: boolean;
}
export interface GridStateEvent<T> {
  previous?: T;
  current?: T;
}

export interface GridTemplateRefs {
  columns: Record<string, TemplateRef<any>>;
  filters: Record<string, TemplateRef<any>>;
}

export interface SelectedRowInfo {
  selectedRow?: any;
  options?: SelectedRowOptions;
}

export interface SelectedRowOptions {
  triggerNext: boolean;
}

export type UpdateSelectedRow = (
  id: string,
  props: AnyPropertiesObject,
  triggerNext?: boolean
) => void;

export type UpdateConflictsCount = {
  id: string;
  conflictsCount: number;
  resolvedConflictsCount: number;
  toReset: boolean;
};

export type GetGridDataReason = GetGridDataReasonEnum | boolean;

export enum GetGridDataReasonEnum {
  newRow = 'newRow',
  saveRow = 'saveRow',
  queryParamChange = 'queryParamChange',
  sortChange = 'sortChange',
  pageChange = 'pageChange'
}

export enum GridItemTypes {
  REQ = 'requirements',
  STORY = 'stories',
  FEEDBACK = 'feedback'
}
