import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Q9CoreConfigService} from '@q9elements/ui-core';
import {Observable} from 'rxjs';

import {handleError} from '../../utils/http.util';

@Injectable()
export class ImportFromJiraDaoService {
  readonly apiUrl = Q9CoreConfigService.environment.API_ENDPOINT;

  constructor(private http: HttpClient) {}

  getImportEstimates(itemType, projectId, statusIds, issueTypeId): Observable<any> {
    const url = `${this.apiUrl}/integrations/jira/import/${projectId}/count`;

    return this.http.post(url, {statusIds, issueTypeId, itemType}).pipe(handleError);
  }

  runImportIssuesFromJira(itemType, projectId, statusIds, issueTypeId): Observable<any> {
    const url = `${this.apiUrl}/integrations/jira/import/${projectId}`;

    return this.http.post(url, {statusIds, issueTypeId, itemType}).pipe(handleError);
  }
}
