<div mat-dialog-title>
  <div class="dialog-title">{{ 'REQUIREMENTS.CHANGE_STATUS_CONFIRM' | translate }}</div>
  <button mat-button mat-dialog-close class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p>{{ data.warningMessage | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" class="q9-btn q9-btn-border" tabindex="1" mat-dialog-close>
    {{ 'GENERAL.CANCEL' | translate }}
  </button>
  <button
    mat-raised-button
    class="q9-btn"
    color="primary"
    mat-dialog-close="submit"
    cdkFocusInitial
  >
    {{ 'GENERAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>
