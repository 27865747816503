import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {has} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BaseResolver {
  constructor(private authService: AuthService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (has(route.queryParams, 'logout')) {
      this.authService.logOut().subscribe(() => this.router.navigate(['signin']));
    } else {
      this.router.navigate(['t']);
    }
  }
}
