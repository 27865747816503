import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

import {environment} from '../../../../environments/environment';
import {Q9UserService} from '@q9elements/ui-core';

@Injectable()
export class SalesForceRoleDialogDaoService {
  userId;

  constructor(private http: HttpClient, private q9UserService: Q9UserService) {
    this.userId = this.q9UserService.getUserId();
  }

  setSFrole(payload): Observable<any> {
    return this.http.put(`${environment.API_ENDPOINT}/users/${this.userId}`, payload);
  }
}
