<div mat-dialog-title>
  <div class="dialog-title">{{ 'STORIES.IMPORT_FROM_JIRA' | translate }}</div>
  <button mat-button mat-dialog-close="close" class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="q9-modal-error" *ngIf="error$ | async">
  <div class="text">{{ error$ | async }}</div>
</div>
<form autocomplete="off" novalidate [formGroup]="importIssuesForm" (ngSubmit)="importStories()">
  <mat-dialog-content>
    <q9-dev-projects-select formControlName="devProject"> </q9-dev-projects-select>

    <div>
      <mat-form-field
        class="q9-form-field q9-full-width"
        [floatLabel]="'always'"
        appearance="outline">
        <mat-label>{{ 'STORIES.IMPORT_FROM_JIRA_ISSUE_TYPE_LABEL' | translate }}</mat-label>
        <mat-select
          [matTooltip]="'JIRA.ISSUE_TYPES_NOT_EXIST_TOOLTIP' | translate: {itemType: data.itemType}"
          [matTooltipDisabled]="!!(issueTypes$ | async)?.length"
          [disabled]="!(issueTypes$ | async)?.length || !getFormControl('devProject').value"
          class="q9-select"
          formControlName="issueType"
          [placeholder]="'STORIES.IMPORT_FROM_JIRA_ISSUE_TYPE_PH' | translate">
          <mat-option
            class="q9-select-options"
            *ngFor="let type of (issueTypes$ | async) || []"
            [value]="type">
            {{ type.jiraIssueType.name | decodeHtml}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field
        class="q9-form-field q9-full-width"
        [floatLabel]="'always'"
        appearance="outline">
        <mat-label>{{ 'STORIES.IMPORT_FROM_JIRA_STATUS_LABEL' | translate }}</mat-label>

        <mat-select
          #statusesSelect
          [disabled]="!getFormControl('issueType').value"
          [placeholder]="'STORIES.IMPORT_FROM_JIRA_STATUSES_PH' | translate"
          formControlName="statuses"
          class="q9-select"
          multiple>
          <mat-option
            class="q9-select-options"
            *ngFor="let item of getFormControl('issueType').value?.statusMapping | keyvalue"
            [value]="item.key">
            {{ item.value.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ (!(getFormControl('issueType').value?.statusMapping | keyvalue)?.length &&
          'STORIES.IMPORT_FROM_JIRA_STATUS_MAPPING_NOT_CONFIGURED' ||
          'STORIES.IMPORT_FROM_JIRA_STATUS_NOT_SELECTED' | translate: { type:
          getFormControl('issueType').value?.jiraIssueType.name | decodeHtml }) }}
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="jiraIssuesCount$ | async as storiesCount" class="estimated-import-count">
      <mat-progress-spinner
        *ngIf="inProgress$ | async"
        [color]="'primary'"
        [mode]="'indeterminate'"
        [diameter]="20"></mat-progress-spinner>
      <ng-container *ngIf="!(showCounter$ | async)">
        {{ storiesCount.total }} {{ 'STORIES.IMPORT_ISSUES_FROM_JIRA_FILTER' | translate: {type:
        getFormControl('issueType').value?.jiraIssueType.name | decodeHtml} }}
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" class="q9-btn q9-btn-border" mat-dialog-close="close">
      {{ 'GENERAL.CANCEL' | translate }}
    </button>
    <span
      class="q9-btn"
      [matTooltip]="'JIRA.IMPORT_FROM_JIRA_TOOLTIP' | translate"
      [matTooltipDisabled]="!disableImport"
      matTooltipShowDelay="400"
      matTooltipHideDelay="400">
      <button
        class="q9-btn"
        mat-raised-button
        color="primary"
        [disabled]="importIssuesForm.invalid || (inProgressImport$ | async) || disableImport">
        {{ 'REQUIREMENTS.IMPORT_BTN' | translate }}
      </button>
    </span>
  </mat-dialog-actions>
</form>
