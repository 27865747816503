import {HttpErrorResponse, HttpParams} from '@angular/common/http';

import {forIn} from 'lodash';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

import {Q9CustomEncoder} from '@q9elements/ui-core';

export function composeRequestParams(params): HttpParams {
  let requestParams = new HttpParams({encoder: new Q9CustomEncoder()});

  forIn(params, (value, key) => (requestParams = requestParams.append(key, `${value}`)));

  return requestParams;
}

export const handleError = catchError(({error}: HttpErrorResponse) => throwError(error));
