import {MatDialogRef} from '@angular/material/dialog';
import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Q9SnackBarService, Q9UserService} from '@q9elements/ui-core';

import {exhaustMap} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {enumerable} from '../../../util/enum';
import {AuthService} from '../../../services/auth/auth.service';
import {Q9Validators} from '../../services/form-validator/q9-validators';
import {SalesForceRoleDialogDaoService} from './salesforce-role-dialog-dao.service';
import {IsSubmittedAndDirtyValidator} from '../../services/form-validator/submitted-and-valid.validator';

@Component({
  selector: 'salesforce-role-dialog',
  templateUrl: 'salesforce-role-dialog.component.html',
  styleUrls: ['salesforce-role-dialog.component.scss']
})
export class SalesForceRoleDialogComponent implements OnInit {
  errorStateMatcher = new IsSubmittedAndDirtyValidator();
  formSubject = new Subject<any>();
  sfRoles = enumerable.sfRoles;
  sfForm: UntypedFormGroup;
  submitDisabled = true;
  currentUser;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private q9UserService: Q9UserService,
    private q9SnackBar: Q9SnackBarService,
    private dialogRef: MatDialogRef<SalesForceRoleDialogComponent>,
    private salesForceRoleDialogDao: SalesForceRoleDialogDaoService
  ) {}

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.sfForm = this.initSfForm();
    this.currentUser = this.q9UserService.user;
    this.listenFormSubmit();
  }

  sfRoleChanged() {
    if (this.submitDisabled) {
      this.submitDisabled = false;
    }
  }

  submitSfRole() {
    const formValue = this.sfForm.value;
    this.formSubject.next(formValue);
  }

  listenFormSubmit() {
    this.formSubject
      .pipe(exhaustMap(value => this.salesForceRoleDialogDao.setSFrole(value)))
      .subscribe(
        next => {
          this.dialogRef.close();
          this.q9UserService.user = Object.assign({}, this.currentUser, next);
        },
        reject => {
          this.q9SnackBar.show(reject.error);
        }
      );
  }

  private initSfForm() {
    return this.formBuilder.group({
      sfRole: ['', Q9Validators.required]
    });
  }
}
