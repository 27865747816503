import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamUtilService {
  private teamIndex: number;
  private teamId: string;

  public teamIdSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  setTeamIndex(teamIndex: number): void {
    this.teamIndex = Number(teamIndex);
  }

  setTeamId(teamId: string): void {
    this.teamId = teamId;
  }

  getTeamIndex(): number {
    return this.teamIndex;
  }

  // might be null, for interceptor use only
  getTeamId(): string {
    return this.teamId;
  }

  clearContext(): void {
    this.teamIndex = null;
    this.teamId = null;
  }
}
