<div class="shared-with-dialog">
  <div mat-dialog-title>
    <div class="dialog-title">
      {{ 'SHARED_WITH.MODAL_TITLE' | translate }}
      <span class="q9-modal-subtitle"
        >{{ 'REFERENCE_MODELS.REFERENCE_MODEL' | translate }} "{{ refModelName }}"</span
      >
    </div>

    <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <mat-dialog-content class="dialog-content">
    <div class="heading">
      <div class="filter-col">
        <mat-form-field
          floatLabel="never"
          class="q9-form-field q9-hide-label q9-full-width"
          [floatLabel]="'always'"
          appearance="outline">
          <mat-label>{{ 'MAP.SEARCH' | translate }}</mat-label>
          <input #filterTitle q9Trim matInput autocomplete="off" />
        </mat-form-field>
      </div>

      <div *ngIf="total === 0 && !users.length && !isLoading" class="no-items">
        <img src="./assets/images/path.png" />
        <h3 class="ng-binding">{{ 'SHARED_WITH.MODAL_NO_USERS' | translate }}</h3>
      </div>
    </div>

    <div class="users-container">
      <div class="spinner-container" *ngIf="isLoading; else elseBlock">
        <mat-spinner></mat-spinner>
      </div>

      <ng-template #elseBlock>
        <perfect-scrollbar [config]="scrollbarConfig">
          <ref-model-user
            *ngFor="let controlRight of users"
            (callback)="refModelUserCallBack($event)"
            [refModel]="data.row"
            [user]="controlRight"></ref-model-user>
        </perfect-scrollbar>
      </ng-template>
    </div>
  </mat-dialog-content>

  <div class="modal-foo∑er">
    <mat-paginator
      #paginator
      hidePageSize="true"
      color="primary"
      [pageSize]="10"
      [length]="total"
      [pageSizeOptions]="[10]">
    </mat-paginator>
  </div>
</div>
