import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {composeRequestParams, handleError} from '../../_shared/utils/http.util';
import {Feedback, FeedbackLink} from '../model/feedback.interface';
import {Q9maFeedbackDaoService} from '@q9elements/ma';

@Injectable({
  providedIn: 'root'
})
export class FeedbackDaoService extends Q9maFeedbackDaoService {
  constructor(protected http: HttpClient) {
    super();
  }

  getFeedback<T>(params: any): Observable<T> {
    const requestParams = composeRequestParams(params);

    return this.http
      .get<T>(this.baseUrl, {params: requestParams})
      .pipe(handleError) as Observable<T>;
  }

  getChangesMeta(feedbackId): Observable<FeedbackLink<any>[]> {
    return this.http
      .get(`${this.baseUrl}/${feedbackId}/links/meta`)
      .pipe(handleError) as Observable<FeedbackLink<any>[]>;
  }

  getDocumentationMeta(feedbackId): Observable<FeedbackLink<any>[]> {
    return this.http
      .get(`${this.baseUrl}/${feedbackId}/documentations/meta`)
      .pipe(handleError) as Observable<FeedbackLink<any>[]>;
  }

  removeChange({feedbackId, linkId}): Observable<{links: Array<any>}> {
    return this.http
      .delete(`${this.baseUrl}/${feedbackId}/links/${linkId}`)
      .pipe(handleError) as Observable<{links: Array<any>}>;
  }

  getFeedbackById(feedbackId): Observable<Feedback> {
    return this.http.get<Feedback>(`${this.baseUrl}/${feedbackId}`);
  }

  getFeedbackPermission(feedbackId, rmnId) {
    let requestParams;

    if (rmnId) {
      requestParams = composeRequestParams({rmnId});
    }

    return this.http.get(`${this.baseUrl}/${feedbackId}/permission`, {params: requestParams});
  }
}
