import {Injectable} from '@angular/core';

import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AllowToRedirectService {
  MAIN_APP_URL: boolean;
  DIAGRAM_URL: boolean;
  TEAM_APP: boolean;
  REF_MODEL_APP: boolean;
  RELEASE_URL: boolean;
  DATA_STRUCTURE_URL: boolean;
  REPORTS_APP: boolean;
  ENTERPRISE_URL: boolean;
  SYS_ADMIN_APP: boolean;
  PARTNER_APP: boolean;
  MAP_MANAGEMENT_APP: boolean;
  API: boolean;
  TASK_LIST: boolean;

  private setupEnvironments(redirect) {
    this.MAIN_APP_URL = this.composeRegExp(environment.MAIN_APP_URL).test(redirect);
    this.DIAGRAM_URL = this.composeRegExp(environment.DIAGRAM_URL).test(redirect);
    this.TEAM_APP = this.composeRegExp(environment.TEAM_APP).test(redirect);
    this.REF_MODEL_APP = this.composeRegExp(environment.REF_MODEL_APP).test(redirect);
    this.RELEASE_URL = this.composeRegExp(environment.RELEASE_URL).test(redirect);
    this.DATA_STRUCTURE_URL = this.composeRegExp(environment.DATA_STRUCTURE_URL).test(redirect);
    this.REPORTS_APP = this.composeRegExp(environment.REPORTS_APP).test(redirect);
    this.ENTERPRISE_URL = this.composeRegExp(environment.ENTERPRISE_URL).test(redirect);
    this.SYS_ADMIN_APP = this.composeRegExp(environment.SYS_ADMIN_APP).test(redirect);
    this.MAP_MANAGEMENT_APP = this.composeRegExp(environment.MAP_MANAGEMENT_APP).test(redirect);
    this.API = this.composeRegExp(environment.API_ENDPOINT).test(redirect);
    this.TASK_LIST = /^\/tasks+/.test(redirect);
  }

  public allowed(redirect) {
    this.setupEnvironments(redirect);
    return (
      this.MAIN_APP_URL ||
      this.DIAGRAM_URL ||
      this.TEAM_APP ||
      this.REF_MODEL_APP ||
      this.RELEASE_URL ||
      this.DATA_STRUCTURE_URL ||
      this.REPORTS_APP ||
      this.ENTERPRISE_URL ||
      this.SYS_ADMIN_APP ||
      this.MAP_MANAGEMENT_APP ||
      this.TASK_LIST ||
      this.API
    );
  }

  private composeRegExp(url: string): RegExp {
    return new RegExp('^' + url.replace(/\//g, '\\/') + '.*');
  }
}
