import {get} from 'lodash';
import {UntypedFormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

/** Error when invalid control is dirty, touched, and submitted. */
export class IsSubmittedAndDirtyValidator implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && isSubmitted);
  }
}

export class IsControlDependOnForm implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmittedAndInvalid = get(form, 'submitted') && !get(form, 'valid');
    return isSubmittedAndInvalid;
  }
}

export class NoErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control): boolean {
    return false;
  }
}
