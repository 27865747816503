import {Injectable} from '@angular/core';
import {DocumentReference} from '@angular/fire/compat/firestore';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {FilterDescriptor} from '@progress/kendo-data-query';
import {Q9UserService} from '@q9elements/ui-core';
import {deleteField, DocumentSnapshot} from 'firebase/firestore';
import {get, isEmpty, pull, set} from 'lodash';
import {BehaviorSubject, of} from 'rxjs';
import {catchError, filter, map, mergeMap, take, takeWhile, tap} from 'rxjs/operators';

import {FireStoreService} from '../../services/firestore/firestore.service';
import {enumerable} from '../../util/enum';
import {NavItemName, RoutePath} from '../components/models/nav-item.interface';
import {
  FilterConfig,
  FirestorePropertyName,
  UserFirestoreDocument,
  UserSettings
} from '../models/user-settings.interface';
import {Layout, LAYOUT} from 'src/app/_shared/models/layout.interface';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  currentUserSettingsSubject: BehaviorSubject<UserSettings> = new BehaviorSubject<UserSettings>({});
  lastVisitedTeamsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  activeRoutePath: RoutePath;
  currUserSettingsFirestoreId: string;

  skipFirestoreSave = true;

  constructor(
    private q9UserService: Q9UserService,
    private router: Router,
    private fireStoreService: FireStoreService,
    private route: ActivatedRoute
  ) {
    this.setupLastActiveRoutePathSubscriber();
    // firestore().settings({experimentalForceLongPolling: true});
    // TODO: include this when it becomes recommended for production use
    // firestore().enablePersistence({experimentalTabSynchronization: true});
  }

  getDataFromFirestore() {
    this.currUserSettingsFirestoreId = null;
    this.setCurrentUserSettings({});

    return this.fireStoreService.readDocument(`users/${this.userId}`).pipe(
      tap((userSnapshot: DocumentSnapshot | any) => {
        if (!userSnapshot.exists) {
          // create user doc & push current team to last visited
          this.fireStoreService
            .createDocument('users', {lastVisitedTeams: [this.teamId]}, this.userId)
            .then(() => this.lastVisitedTeamsSubject.next([this.teamId]));
        }

        return userSnapshot.exists;
      }),
      mergeMap(userSnapshot => this.getCurrStateFromUserRef(userSnapshot)),
      catchError(() => of(true))
    );
  }

  private subcribeToUserSettings() {
    const teamId = this.teamId;

    this.fireStoreService
      .valueChanges(`users/${this.userId}`)
      .pipe(
        filter(userDoc => get(userDoc, `teams.${teamId}`)),
        takeWhile(() => teamId === this.teamId),
        take(1)
      )
      .subscribe(
        (userDoc: UserFirestoreDocument) =>
          (this.currUserSettingsFirestoreId = userDoc.teams[teamId].id)
      );
  }

  setFilterConfig(
    filterConfig: FilterConfig | FilterDescriptor[],
    viewId?: string
  ): Promise<DocumentReference | void> | void {
    const path = `${this.activeRoutePath}.views.${viewId || this.activeRef || 'default'}`;
    const value = isEmpty(filterConfig) ? deleteField() : filterConfig;

    this.setCurrentUserSettings(set(this.currentUserSettings, path, filterConfig));

    if (this.currUserSettingsFirestoreId) {
      return this.fireStoreService.updateDocument(`settings/${this.currUserSettingsFirestoreId}`, {
        [path]: value
      });
    } else if (!isEmpty(filterConfig)) {
      return this.createSettingsDocument(set({}, path, value));
    }
  }

  setColumnWidth({columnName, columnWidth}: {columnName?: string; columnWidth: number | Object}) {
    return this.setLastActiveFeature(this.columnWidthPath(columnName), columnWidth);
  }

  getColumnWidth(columnName?: string) {
    return get(this.currentUserSettings, this.columnWidthPath(columnName));
  }

  // might be used to modify list's filterConfig
  modifyWithValuesFromFireStore(filterConfig: any[]): any[] {
    return filterConfig.map(filterValue => {
      filterValue.value = get(this.filterFromFirestore, `${filterValue.name}`);
      return filterValue;
    });
  }

  setCurrentUserSettings(value: UserSettings) {
    this.currentUserSettingsSubject.next(value);
  }

  getLastActiveRoutePath(
    navItem = this.currentNavItem,
    defaultPath: RoutePath = 'requirements'
  ): RoutePath {
    return (
      (get(this.currentUserSettings, `${navItem}.activeRoutePath`) as RoutePath) || defaultPath
    );
  }

  getFiltersFromFirestore(viewId?: string) {
    const path = `${this.activeRoutePath}.views.${viewId || this.activeRef || 'default'}`;

    return get(this.currentUserSettings, path, []);
  }

  get filterFromFirestore(): any {
    return get(this.currentUserSettings, this.propertyPath());
  }

  get currentUserSettings(): UserSettings {
    return this.currentUserSettingsSubject.value;
  }

  get isCurrNavItemChanges(): boolean {
    return this.currentNavItem === 'changes';
  }

  get currentNavItem(): NavItemName {
    return enumerable.routePaths[this.activeRoutePath].navItemName as NavItemName;
  }

  get activeRef(): string {
    return this.route.snapshot.queryParamMap.get('ref');
  }

  isRoutePath(routePath): routePath is RoutePath {
    return enumerable.routePaths.hasOwnProperty(routePath);
  }

  private getCurrStateFromUserRef(userSnapshot: DocumentSnapshot): Promise<any> {
    const {teams = {}, lastVisitedTeams = []} = userSnapshot.data();
    const userSettingsRef: DocumentReference = teams[this.teamId];

    this.setLastVisitedTeams(lastVisitedTeams);

    if (userSettingsRef) {
      this.currUserSettingsFirestoreId = userSettingsRef.id;
      return userSettingsRef
        .get()
        .then(userSettings => this.setCurrentUserSettings(userSettings.data() as UserSettings));
    } else {
      this.subcribeToUserSettings();
    }

    return Promise.resolve();
  }

  private setLastVisitedTeams(lastVisitedTeams: string[]) {
    const userTeams = (this.q9UserService.user as any).teams
      .filter(({team}) => team.plan.type !== 'suspended')
      .map(({team}) => team.id);

    pull(lastVisitedTeams, this.teamId);
    lastVisitedTeams.unshift(this.teamId);

    const newLastVisitedTeams = lastVisitedTeams
      .filter(visitedTeam => userTeams.includes(visitedTeam))
      .slice(0, enumerable.lastVisitedTeamsMaxCount);

    this.fireStoreService.updateDocument(`users/${this.userId}`, {
      lastVisitedTeams: newLastVisitedTeams
    });

    this.lastVisitedTeamsSubject.next(newLastVisitedTeams);
  }

  private setLastActiveRoutePath(activeRoutePath): Promise<DocumentReference | void> {
    return this.setLastActiveFeature(`${this.currentNavItem}.activeRoutePath`, activeRoutePath);
  }

  setLastActiveFeature(featurePath: string, value: any): Promise<DocumentReference | void> {
    this.setCurrentUserSettings(set(this.currentUserSettings, featurePath, value));

    if (this.currUserSettingsFirestoreId) {
      return this.fireStoreService.updateDocument(`settings/${this.currUserSettingsFirestoreId}`, {
        [featurePath]: value
      });
    } else {
      return this.createSettingsDocument(this.currentUserSettings);
    }
  }

  private createSettingsDocument(settingsValue) {
    return this.fireStoreService
      .createDocument('settings', settingsValue)
      .then((settingsRef: DocumentReference | any) => {
        this.currUserSettingsFirestoreId = settingsRef.id;

        return this.createRefToUserSettings(settingsRef);
      });
  }

  private createRefToUserSettings(settingsRef) {
    return this.fireStoreService
      .updateDocument(`users/${this.userId}`, {
        [`teams.${this.teamId}`]: settingsRef
      })
      .catch((): any => {
        return this.fireStoreService.createDocument(
          `users`,
          {
            teams: {[this.teamId]: settingsRef}
          },
          this.userId
        );
      });
  }

  private setupLastActiveRoutePathSubscriber() {
    this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        map((event: any) => event.snapshot.routeConfig.path),
        filter(path => this.isRoutePath(path)),
        tap(activeRoutePath => (this.activeRoutePath = activeRoutePath)),
        filter(() => this.canSetLastActiveRoutePath)
      )
      .subscribe(activeRoutePath => this.setLastActiveRoutePath(activeRoutePath));
  }

  private propertyPath(propertyName: FirestorePropertyName = 'filter'): string {
    // array might change in the future, depending on the navItem
    const pathNameArray = ['activeRoutePath', 'activeRef', 'viewMode', 'filter'];
    const pathArray = pathNameArray
      .slice(0, pathNameArray.indexOf(propertyName) + 1)
      .map(pathName => this[pathName] || pathName);

    return pathArray.join('.');
  }

  private columnWidthPath(columnName?: string) {
    if (!columnName) {
      return `${this.activeRoutePath}.common.grid.columnWidth`;
    }

    return `${this.activeRoutePath}.common.grid.columnWidth.${columnName}`;
  }

  private get viewMode(): Layout {
    // grid only for changes(requirements & stories)
    return this.isCurrNavItemChanges ? LAYOUT.GRID : LAYOUT.LIST;
  }

  private get canSetLastActiveRoutePath(): boolean {
    const lastActiveRoutePath = this.getLastActiveRoutePath() || 'requirements';
    const navItemHasMultipleRoutes = this.isCurrNavItemChanges;

    return navItemHasMultipleRoutes && lastActiveRoutePath !== this.activeRoutePath;
  }

  private get teamId(): string {
    return this.q9UserService.getTeamId();
  }

  private get userId(): string {
    return this.q9UserService.getUserId();
  }
}
