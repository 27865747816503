import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Q9HtmlEncoderPipe} from '@q9elements/ui-core';
import {merge, of as observableOf, Subject} from 'rxjs';
import {catchError, map, switchMap, takeWhile} from 'rxjs/operators';

import {filterFalsy} from '../../../util/rxjs.util';
import {Q9ListLightInterface} from '../q9-list/q9-list-interfaces';
import {Q9ListStore} from '../q9-list/store.service';

@Component({
  selector: 'q9-list-light',
  templateUrl: 'q9-list-light.component.html',
  styleUrls: ['q9-list-light.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Q9ListLightComponent implements OnInit, OnDestroy {
  @Input() config: Q9ListLightInterface;
  @Input() dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public recallChainSubject = new Subject<void>();
  encodeHtml = this.encodeHtmlPipe.transform.bind(this);

  selectedRow;
  aliveObservable = true;
  isLoadingResults: boolean;
  matSortDirection: string;
  matSortActive: string;
  resultsLength: any;

  scrollbarConfig = {
    suppressScrollX: true
  };

  constructor(public store: Q9ListStore, public encodeHtmlPipe: Q9HtmlEncoderPipe) {}

  ngOnInit() {
    this.dataSource.sort = this.sort;

    this.setupComponent();
    this.setupSelectedRowObserver();
    this.initMainObserver();
  }

  ngOnDestroy() {
    this.aliveObservable = false;
  }

  private setupComponent() {
    this.setupDefaults();
  }

  private initMainObserver() {
    const mergeItems: any = [this.sort.sortChange, this.recallChainSubject];

    if (this.config.filter) {
      mergeItems.push(this.config.filter);
    }

    if (this.config.paginated) {
      mergeItems.push(this.paginator.page);
    }

    merge(...mergeItems)
      .pipe(
        takeWhile(() => this.aliveObservable),
        switchMap(() => {
          this.isLoadingResults = true;

          // by default set your filter subject value to null
          const filters = this.config.filter.value || null;
          const params = this.setupParams(filters);

          return this.config.callServer(params).pipe(
            catchError(err => {
              this.isLoadingResults = false;
              // Catch if the  API was reached. Return empty data if not.
              return observableOf([]);
            })
          );
        }),
        map((data: any) => {
          this.isLoadingResults = false;
          this.resultsLength = data.total || data.length;
          return data;
        })
      )
      .subscribe(data => this.afterDataReceived.call(this, data));
  }

  private setupParams(filters) {
    const sortBy = this.sort.active;
    const order = this.sort.direction || '';
    const params = {sort: `${sortBy} ${order.toUpperCase()}`};

    if (this.config.paginated) {
      params['limit'] = this.paginator.pageSize;
      params['skip'] = this.paginator.pageIndex * this.paginator.pageSize;
    }

    /**  Default sorting **/
    if (!sortBy || !order) {
      params['sort'] = `${
        this.config.paginationDefaults.sortBy
      } ${this.config.paginationDefaults.order.toUpperCase()}`;

      if (this.config.paginated) {
        params['skip'] = this.config.paginationDefaults.skip;
        params['limit'] = this.config.paginationDefaults.limit;
      }
    }

    /* Set custom filter */
    if (filters) {
      Object.keys(filters).forEach(key => {
        params[key] = filters[key];
      });
    }

    return params;
  }

  private afterDataReceived(data) {
    const list = data.list || data.items;

    /** case when we don't have any more items on page after item was removed*/
    if (!list.length && this.resultsLength && this.config.paginated) {
      const page = this.paginator.pageIndex;
      this.paginator.pageIndex = page - 1 < 1 ? 0 : page - 1;
      return this.recallChainSubject.next();
    }

    this.dataSource.data = list /*in case of not paginated list*/;
  }

  private setupDefaults() {
    this.matSortActive = this.config.paginationDefaults.sortBy;
    this.matSortDirection = this.config.paginationDefaults.order;
  }

  private setupSelectedRowObserver() {
    this.store
      .getSubscription()
      .pipe(
        filterFalsy(),
        takeWhile(() => this.aliveObservable)
      )
      .subscribe(row => (this.selectedRow = row));
  }
}
