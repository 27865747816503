<div class="invite-user-dialog">
  <div mat-dialog-title>
    <div class="dialog-title">
      {{ (isExternal ? 'SF_CONNECTIONS.INVITE_SF_MODAL_TITLE' :
      'REFERENCE_MODELS.INVITE_MODAL_TITLE' ) | translate }}
      <span class="q9-modal-subtitle">
        {{ (isExternal ? 'SF_CONNECTIONS.ORG_MODEL' : 'REFERENCE_MODELS.REFERENCE_MODEL') |
        translate }} "{{ refModelName | decodeHtml }}"
      </span>
    </div>

    <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div class="q9-modal-error" *ngIf="error">
    <div class="text">{{ error }}</div>
  </div>

  <form autocomplete="off" novalidate (ngSubmit)="submit()" [formGroup]="refModelInviteForm">
    <mat-dialog-content class="dialog-content">
      <div class="container no-padding">
        <div class="row">
          <div class="col-9">
            <div class="q9-modal-form-group">
              <mat-form-field
                class="q9-form-field q9-full-width"
                [floatLabel]="'always'"
                appearance="outline"
                hideRequiredMarker="true">
                <mat-label>{{ 'REFERENCE_MODELS.INVITE_CANDIDATE_LABEL' | translate }}</mat-label>
                <input
                  matInput
                  required
                  minlength="2"
                  cdkFocusInitial
                  (focus)="error = null"
                  [matAutocomplete]="auto"
                  [placeholder]="'REFERENCE_MODELS.CHANGE_OWNER_EMAIL_OR_NAME' | translate"
                  [errorStateMatcher]="this"
                  formControlName="addedUser" />
                <div matSuffix (click)="removeUser()" class="remove-icon" aria-label="Clear">
                  <mat-icon svgIcon="close"></mat-icon>
                </div>

                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayFn"
                  (optionSelected)="onSelectHandler()"
                  autoActiveFirstOption="true">
                  <mat-option *ngFor="let user of users$ | async" [value]="user">
                    <q9-user-info class="q9-user-info candidate" [user]="user"></q9-user-info>
                  </mat-option>
                </mat-autocomplete>

                <mat-error
                  class="error-message"
                  *ngIf="refModelInviteForm.get('addedUser').hasError('required')">
                  {{ 'REFERENCE_MODELS.USER_NOT_SPECIFIED' | translate }}
                </mat-error>

                <mat-error
                  class="error-message"
                  *ngIf="
                    refModelInviteForm.get('addedUser').hasError('invalidUser') &&
                    !refModelInviteForm.get('addedUser').hasError('required')
                  ">
                  {{ 'REFERENCE_MODELS.INVITE_NOT_VALID_USER' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-3 no-left-padding">
            <div class="q9-modal-form-group">
              <mat-form-field
                class="q9-form-field q9-full-width q9-without-label"
                floatLabel="never"
                appearance="outline">
                <mat-select
                  panelClass="q9-select"
                  [disabled]="isRoleDisabled()"
                  formControlName="role">
                  <mat-option class="q9-select-option" value="view">View only</mat-option>
                  <mat-option class="q9-select-option" value="edit">Can edit</mat-option>
                  <mat-option class="q9-select-option" value="manage">Manage</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="q9-modal-form-group">
        <p class="no-candidate" *ngIf="isInviteToSpace && !error">
          {{ inviteWarning | translate: {userEmail: refModelInviteForm.controls['addedUser'].value}
          }}
        </p>
      </div>

      <div class="q9-modal-form-group">
        <mat-form-field
          class="q9-form-field q9-full-width"
          [floatLabel]="'always'"
          appearance="outline">
          <mat-label>{{ 'REFERENCE_MODELS.INVITE_CANDIDATE_MESSAGE' | translate }}</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="10"
            [placeholder]="'REFERENCE_MODELS.INVITE_MODAL_MESSAGE_PH' | translate"
            formControlName="message">
          </textarea>
          <mat-error
            *ngIf="
              refModelInviteForm.get('message').touched &&
              refModelInviteForm.get('message').hasError('maxlength')
            ">
            {{ 'REFERENCE_MODELS.INVITE_MODAL_MESSAGE_ERROR_MAX' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="btns">
      <div class="btns--info">
        <a
          target="_blank"
          class="help-btn"
          matTooltipPosition="after"
          matTooltipShowDelay="400"
          matTooltipHideDelay="400"
          [matTooltip]="'CORE.MAP.SHARING_HELP_HINT' | translate"
          href="https://support.elements.cloud/en/articles/2273973-invite-users-to-access-org-model-or-ref-model">
          <button class="q9-icon-btn" type="button" mat-button>
            <mat-icon class="info" svgIcon="icon-info"></mat-icon>
          </button>
        </a>
      </div>
      <div class="btns--actions">
        <button
          mat-button
          color="primary"
          class="q9-btn q9-btn-border"
          tabindex="1"
          type="button"
          mat-dialog-close>
          {{ 'REFERENCE_MODELS.INVITE_MODAL_BTN_CANCEL' | translate }}
        </button>
        <button mat-raised-button class="q9-btn" color="primary" tabindex="2" cdkFocusInitial>
          {{ 'REFERENCE_MODELS.INVITE_MODAL_BTN_INVITE' | translate }}
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
