import {Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TrainingService} from '../../services/trainings/trainings.service';
@Component({
  selector: 'cancel-release-training',
  templateUrl: './cancel-release-training.component.html',
  styleUrls: ['./cancel-release-training.component.scss']
})
export class CancelReleaseTrainingComponent implements OnInit {
  inProgress: boolean;
  error: string | null;
  cancelFrom: Map<string, string>;

  constructor(
    public dialogRef: MatDialogRef<CancelReleaseTrainingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private trainingService: TrainingService
  ) {
    this.cancelFrom = new Map([
      ['release', 'selectedRelease'],
      ['training', 'selectedTraining']
    ]);
  }

  ngOnInit() {}

  cancelReleaseTraining() {
    this.inProgress = true;
    const type = this.data.type;
    const cancelFrom = this.cancelFrom.get(type);
    const params = {
      id: this.data[cancelFrom].id,
      type
    };
    this.error = '';
    this.trainingService.cancelReleaseTraining(params).subscribe(
      success => {
        this.dialogRef.close(success);
      },
      error => {
        this.error = error.error;
        this.inProgress = false;
      }
    );
  }
}
