<div class="q9-list-component-light">
  <div class="q9-table" [hidden]="!dataSource.data.length || isLoadingResults">
    <div class="records-list">
      <perfect-scrollbar [config]="scrollbarConfig">
        <div class="table-wrapper">
          <mat-table
            #table
            matSort
            class="q9-list-light-table"
            cdkScrollable
            matSortDisableClear
            [dataSource]="dataSource"
            [matSortActive]="matSortActive"
            [matSortDirection]="matSortDirection"
          >
            <ng-container
              *ngFor="let header of config.headers; let first = first"
              [matColumnDef]="header.key"
            >
              <mat-header-cell
                *matHeaderCellDef
                [disabled]="header.disableSort"
                [ngStyle]="{flex: header.flex || 1}"
                [class.hide-sort-arrow]="config.hideSorting"
                [hidden]="header.isVisible && !header.isVisible()"
                mat-sort-header
                >{{ header.name | translate }}
              </mat-header-cell>

              <ng-container *ngIf="!header.type">
                <mat-cell
                  *matCellDef="let row"
                  [ngStyle]="{flex: header.flex || 1}"
                  [hidden]="header.isVisible && !header.isVisible()"
                >
                  <span
                    class="row-content"
                    [ngStyle]="{color: header.color ? header.color(row) : ''}"
                    [innerHTML]="
                      header.transform ? header.transform(row, encodeHtml) : row[header.key]
                    "
                  >
                  </span>
                </mat-cell>
              </ng-container>
            </ng-container>

            <mat-header-row *matHeaderRowDef="config.displayedColumns"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: config.displayedColumns"
              [ngClass]="{'row-selected': selectedRow?.id === row.id}"
            >
            </mat-row>
          </mat-table>

          <mat-paginator
            #paginator
            [length]="resultsLength"
            [pageSize]="config.pageSize"
            [hidden]="!config.paginated"
            [pageSizeOptions]="config.pageSizeOptions"
          >
          </mat-paginator>
        </div>
      </perfect-scrollbar>
    </div>
  </div>

  <ng-container>
    <ng-content select=".no-items"></ng-content>
  </ng-container>

  <ng-container>
    <ng-content *ngIf="isLoadingResults" select=".spinner"></ng-content>
  </ng-container>
</div>
