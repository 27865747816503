<div mat-dialog-title>
  <div class="dialog-title">{{ 'REFERENCE_MODELS.GDPR_STAT_DONUT' | translate }}</div>
  <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<mat-dialog-content class="dialog-content">
  <div class="gdpr-donut-chart">
    <google-chart [data]="gdprStatusChartConfig"> </google-chart>
  </div>

  <div class="gdpr-stat-block">
    <table>
      <tr *ngFor="let object of gdprStates | keys">
        <td>
          <span
            class="color-dot"
            [ngStyle]="{'background-color': gdprStates[object.key].color}"
          ></span>
          <span class="legend-state-name">{{ gdprStates[object.key].label | translate }}</span>
        </td>
        <td>
          {{ (gdprStatData[object.key] / gdprStatData.allGdprNodes) * 100 | number: '1.0-1' }}%
        </td>
        <td>
          {{ gdprStatData[object.key] }}
          <span *ngIf="gdprStatData[object.key] == '1'">field</span>
          <span *ngIf="gdprStatData[object.key] != '1'">fields </span>
        </td>
      </tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" class="q9-btn q9-btn-border" tabindex="1" mat-dialog-close>
    {{ 'GENERAL.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
