import {Injectable} from '@angular/core';
import {Q9CoreConfigService, Q9PermissionsService, Q9UserService} from '@q9elements/ui-core';
import {get} from 'lodash';
import moment from 'moment';

import {ConnectionQueryParamsInterface} from '../models/sf-connections.interface';
import {SfConnectionsStore} from './sf-connections-store.service';

@Injectable({
  providedIn: 'root'
})
export class SfConnectionsUtilService {
  constructor(
    private sfConnectionsStore: SfConnectionsStore,
    private q9UserService: Q9UserService,
    private q9PermissionsService: Q9PermissionsService
  ) {}

  canConnectNewOrg(): boolean {
    const {team, isPlayground, isTeamAdmin, isMsSpace} = this.q9PermissionsService;

    if (isPlayground) {
      return !get(team, 'team.sfImplementations[0].sf.0', false);
    }

    return isMsSpace || isTeamAdmin;
  }

  public constructUrlForConnection(type, implementation, isReconnect?, orgId?) {
    const teamIndex = this.q9UserService.getTeamIndex();
    const teamId = this.q9UserService.getTeamId();
    const {isMsSpace} = this.q9PermissionsService;
    const {
      MAIN_APP_URL,
      SALESFORCE_CLIENT_ID: client_id,
      SALESFORCE_URL,
      SALESFORCE_URL_TEST
    } = Q9CoreConfigService.environment;
    const url = type === 'sandbox' ? SALESFORCE_URL_TEST : SALESFORCE_URL;
    const queryParams: ConnectionQueryParamsInterface = {
      client_id,
      redirect_uri: `${MAIN_APP_URL}/auth/${isReconnect ? 'reconnect' : 'connect'}/callback`,
      response_type: 'code',
      state: {
        orgId,
        teamId,
        teamIndex,
        connectOrg: !isReconnect,
        implementationId: implementation,
        isSandbox: type === 'sandbox',
        reconnectOrg: isReconnect
      }
    };

    if (isMsSpace) {
      queryParams.state.type = 'consulting';
    }

    return `${url}?${this.convertConnectionParamsToString(queryParams)}`;
  }

  public constructUrlForAuth(orgInfo) {
    const teamIndex = this.q9UserService.getTeamIndex();
    const teamId = this.q9UserService.getTeamId();
    const {
      MAIN_APP_URL,
      SALESFORCE_CLIENT_ID: client_id,
      SALESFORCE_URL,
      SALESFORCE_URL_TEST
    } = Q9CoreConfigService.environment;
    const {orgId, isSandbox} = orgInfo;
    const url = isSandbox ? SALESFORCE_URL_TEST : SALESFORCE_URL;
    const queryParams: ConnectionQueryParamsInterface = {
      client_id,
      redirect_uri: `${MAIN_APP_URL}/auth/refresh/callback`,
      response_type: 'code',
      state: {
        orgId,
        teamId,
        teamIndex,
        isSandbox
      }
    };

    return `${url}?${this.convertConnectionParamsToString(queryParams)}`;
  }

  private convertConnectionParamsToString({state = {}, ...params}) {
    params.state = Object.entries(state)
      .map(function (pair) {
        return pair.join(':');
      })
      .join(',');

    return new URLSearchParams(params).toString();
  }

  public parseSfCallbackQueryParams(queryParams) {
    const code = queryParams.code;
    const paramsStr = queryParams.state.split(',');
    const paramsForParse = [
      'teamIndex',
      'teamId',
      'connectOrg',
      'implementationId',
      'isSandbox',
      'orgId',
      'type',
      'reconnectOrg'
    ];
    const parsedValues: any = {code};
    paramsForParse.forEach(p => {
      const paramString = paramsStr.find(ps => ps.startsWith(p));
      if (paramString) {
        parsedValues[p] = paramString.replace(`${p}:`, '');
      }
    });

    return parsedValues;
  }

  /** This method will get random time between 9pm(21:00) and 7am(7:00) hour and return object
   * {
   *   uiTime: time in users time zone (simple string: '1:00') - need to show it in dialog
   *   backEndTime: moment time in utc - will send on backend for save in DB
   * } */
  public getRandomScheduleTime() {
    const randomHour = this.getRandomNumber();
    const uiTime = `${randomHour}:00`;
    const value = uiTime.split(':')[0];
    const backEndTime = moment().hours(+value).minutes(0).seconds(0);

    return {backEndTime, uiTime};
  }

  private getRandomNumber() {
    const startDate = moment().clone().set('hours', 21);
    const endDate = moment().clone().set('hours', 7);
    const randomHoursForScheduling = [];
    while (startDate.get('hour') !== endDate.get('hour')) {
      randomHoursForScheduling.push(startDate.add(1, 'hour').get('hour'));
    }
    const num = Math.floor(Math.random() * Math.floor(randomHoursForScheduling.length - 1));
    return randomHoursForScheduling[num];
  }

  public getDaysLeft(suspendDate: string) {
    const suspendDateStart = moment(suspendDate);

    return suspendDateStart.diff(moment(), 'days');
  }
}
