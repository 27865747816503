import {PreloadingStrategy, Route} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, EMPTY} from 'rxjs';
import {get} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return get(route, 'data.preload') && CustomPreloadingStrategy.hasGoodConnection
      ? load()
      : EMPTY;
  }

  static get hasGoodConnection(): boolean {
    const {connection} = navigator as any;

    if (connection) {
      const avoidedConnections = ['slow-2g', '2g'];
      const {effectiveType = '', saveData} = connection;

      return !saveData && !avoidedConnections.includes(effectiveType);
    }

    return true;
  }
}
