import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {get} from 'lodash';

import {AuthService} from '../../services/auth/auth.service';

@Injectable({providedIn: 'root'})
export class UserResolver {
  constructor(private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const availableStates = route.data['userState'];
    const signInWithToken = route.data['signInWithToken'];

    if (route.queryParams.redirect) {
      this.authService.cleanCookie();
    }

    if (!signInWithToken) {
      return this.authService.checkState(availableStates);
    }

    return this.authService
      .checkState(availableStates)
      .then(() => {
        const token = route.params['token'].replace(/elements/g, '.');
        this.checkForDiagram(route);
        this.checkForRefModel(route);
        return this.authService.signinWithToken(token);
      })
      .then((user: any) => {
        const teamIndex = get(user, 'userInTeam.teamIndex', 0);

        return this.authService.checkRedirectUrl(route.queryParams, teamIndex);
      });
  }

  private checkForDiagram(route: {queryParams?}) {
    const {
      queryParams: {diagramId, v = 'draft'}
    } = route;
    if (diagramId) {
      localStorage.setItem('diagramId', diagramId);
      localStorage.setItem('diagramState', v);
    } else if (localStorage.getItem(diagramId)) {
      localStorage.removeItem('diagramId');
      localStorage.removeItem('diagramState');
    }
  }

  private checkForRefModel(route: {queryParams?}) {
    const {
      queryParams: {refModelId}
    } = route;
    if (refModelId) {
      localStorage.setItem('refModelId', refModelId);
    } else if (localStorage.getItem(refModelId)) {
      localStorage.removeItem('refModelId');
    }
  }
}
