import {Layout, LAYOUT} from 'src/app/_shared/models/layout.interface';
import {LOCAL_STORAGE_KEYS} from 'src/app/_shared/utils/constants';

export const DEFAULT_LAYOUT = LAYOUT.GRID;

export function isLayout(value: unknown): value is Layout {
  return value === LAYOUT.LIST || value === LAYOUT.GRID;
}

export function getLayout(): Layout {
  const layout = localStorage.getItem(LOCAL_STORAGE_KEYS.LAYOUT);

  return isLayout(layout) ? layout : DEFAULT_LAYOUT;
}
