import {Injectable} from '@angular/core';
import {Q9AttachmentHelperService, Q9PermissionsService, Q9UserService} from '@q9elements/ui-core';

@Injectable({
  providedIn: 'root'
})
export class AttachmentHelperService extends Q9AttachmentHelperService {
  constructor(
    protected q9UserService: Q9UserService,
    protected q9PermissionsService: Q9PermissionsService
  ) {
    super();
  }

  getAttachmentsListEnv(): any {
    const variables = super.getAttachmentsListEnv();
    return {
      ...variables,
      app: 'mainApp'
    };
  }
}
