import {AfterContentInit, Component, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {tap} from 'rxjs/operators';

import {Q9ListStore} from '../../components/q9-list/store.service';
import {IsSubmittedAndDirtyValidator} from '../../services/form-validator/submitted-and-valid.validator';
import {TrainingService} from '../../services/trainings/trainings.service';

@Component({
  selector: 'remind-complete-training',
  templateUrl: './remind-complete-training.component.html',
  styleUrls: ['./remind-complete-training.component.scss']
})
export class RemindCompleteTrainingComponent implements AfterContentInit {
  form: UntypedFormGroup;
  showSpinner = false;
  release: any;
  error: string;
  message: string;
  requiredBy: Date;
  datePickerConfig = {
    appendTo: 'body',
    format: 'YYYY/MM/DD, h:mm:ss A',
    min: this.currentDate(),
    firstDayOfWeek: 'mo'
  };
  remindFrom: Map<string, string>;
  matcher: any;
  numberOfUsers = 0;
  remindMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<RemindCompleteTrainingComponent>,
    private trainingService: TrainingService,
    private translate: TranslateService,
    private store: Q9ListStore
  ) {
    this.remindFrom = new Map([
      ['release', 'selectedRelease'],
      ['training', 'selectedTraining']
    ]);
  }

  get messageErrorMinLength() {
    return (
      this.form.get('message') &&
      this.form.get('message').errors &&
      this.form.get('message').errors.minlength
    );
  }

  get messageErrorMaxLength() {
    return (
      this.form.get('message') &&
      this.form.get('message').errors &&
      this.form.get('message').errors.maxlength
    );
  }

  ngAfterContentInit() {
    this.buildForm();
    this.release = this.passedData.selectedRelease;
    this.matcher = new IsSubmittedAndDirtyValidator();
    this.numberOfUsers = this.passedData.numberOfUsers;
    const message = this.translate.instant('TRAININGS.SEND_REMIND_MESSAGE');
    this.remindMessage =
      this.numberOfUsers === 1
        ? `${message} ${this.numberOfUsers} user`
        : `${message} ${this.numberOfUsers} users`;
  }

  buildForm() {
    this.form = this.fb.group({
      message: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(5000)
        ])
      ],
      requiredBy: [this.getDate(), Validators.required]
    });
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().substring(0, 10);
  }

  disableForm() {
    if (this.form.controls['message'] && this.form.controls['requiredBy']) {
      this.form.controls['message'].disable();
      this.form.controls['requiredBy'].disable();
    }
  }

  enableForm() {
    if (this.form.controls['message'] && this.form.controls['requiredBy']) {
      this.form.controls['message'].enable();
      this.form.controls['requiredBy'].enable();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      this.disableForm();
      this.showSpinner = true;
      this.error = '';
      const message = this.form.get('message').value;
      const requiredBy = new Date(this.form.get('requiredBy').value).toISOString();
      const type = this.passedData.type;
      const selecetedFrom = this.remindFrom.get(type);
      const params = {
        id: this.passedData[selecetedFrom].id,
        type,
        message,
        requiredBy
      };

      this.trainingService
        .remindCompleteTraining(params)
        .pipe(
          tap(() => {
            if (this.passedData[selecetedFrom].requiredBy !== params.requiredBy) {
              const selectedTraining = this.store.getValue();
              selectedTraining.requiredBy = requiredBy;
              this.store.set('store', selectedTraining);
            }
          })
        )
        .subscribe(
          success => {
            this.dialogRef.close(true);
            this.showSpinner = false;
            this.enableForm();
          },
          error => {
            this.showSpinner = false;
            this.error = error.error;
            this.enableForm();
          }
        );
    }
  }

  private getDate() {
    return moment()
      .utc()
      .add(7, 'd')
      .set({hour: 0, minute: 0, second: 0})
      .format('MM/DD/YYYY, h:mm:ss A');
  }
}
