import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {UserResolver} from './account/services/user-resolver.service';
import {CustomPreloadingStrategy} from './custom-preloading-strategy';
import {SfCallbackReasonResolver} from './sf-connections/services/sf-callback-reason.resolver';
import {SfResponseParseResolver} from './sf-connections/services/sf-response-parse.resolver';
import {TeamIndexResolver} from './teams/resolvers/team-index.resolver';
import {mainModuleMatcher, spacesSwitched} from './util/routing.util';
import {EmptyComponent} from './_main/components/empty/empty.component';
import {BaseResolver} from './_main/resolvers/base.resolver';
import {IsPublicCloudGuard} from './_main/resolvers/is-private-cloud.guard';
import {UserSettingsResolver} from './_main/resolvers/user-settings.resolver';

const router: Routes = [
  {
    path: '',
    component: EmptyComponent,
    resolve: {logOutChecker: BaseResolver}
  },
  {
    matcher: mainModuleMatcher,
    data: {
      userState: ['active'],
      preload: true
    },
    resolve: {
      teamIndex: TeamIndexResolver,
      userSettings: UserSettingsResolver
    },
    runGuardsAndResolvers: spacesSwitched,
    loadChildren: () => import('./_main/main.module').then(m => m.MainModule)
  },
  {
    path: 'signin',
    resolve: {user: UserResolver},
    data: {
      userState: ['unauthorized']
    },
    loadChildren: () => import('./account/sign-in/sign-in.module').then(m => m.SignInModule)
  },
  {
    path: 'signup-success',
    resolve: {user: UserResolver},
    data: {
      userState: ['not_verified', 'invited']
    },
    loadChildren: () =>
      import('./account/sign-up-success/sign-up-success.module').then(m => m.SignUpSuccessModule)
  },
  {
    path: 'forgot-pass',
    resolve: {user: UserResolver},
    data: {userState: ['unauthorized', 'active']},
    loadChildren: () =>
      import('./account/forgot-pass/forgot-pass.module').then(m => m.ForgotPassModule)
  },
  {
    path: 'new-pass/:token',
    loadChildren: () => import('./account/new-pass/new-pass.module').then(m => m.NewPassModule)
  },
  {
    path: 'auth/callback',
    data: {userState: ['unauthorized']},
    resolve: {user: UserResolver},
    loadChildren: () =>
      import('./account/social-sign-in/social-sign-in.module').then(m => m.SocialSignInModule)
  },
  {
    path: 'auth/refresh/callback',
    resolve: {
      parsedValues: SfResponseParseResolver,
      callbackReason: SfCallbackReasonResolver
    },
    loadChildren: () =>
      import('./sf-callback-handler/sf-callback-handler.module').then(
        m => m.SfCallbackHandlerModule
      )
  },
  {
    path: 'auth/connect/callback',
    resolve: {
      parsedValues: SfResponseParseResolver,
      callbackReason: SfCallbackReasonResolver
    },
    loadChildren: () =>
      import('./sf-callback-handler/sf-callback-handler.module').then(
        m => m.SfCallbackHandlerModule
      )
  },
  {
    path: 'account-accept',
    loadChildren: () =>
      import('./account/account-accept/account-accept.module').then(m => m.AccountAcceptModule)
  },
  {
    path: 'signup',
    resolve: {user: UserResolver},
    data: {userState: ['unauthorized']},
    canActivate: [IsPublicCloudGuard],
    loadChildren: () => import('./account/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
  {
    path: 'account/email-change/:token',
    loadChildren: () =>
      import('./account/account-email-change/account-email-change.module').then(
        m => m.AccountEmailChangeModule
      )
  },
  {
    path: 'salesforce-login/:token',
    component: EmptyComponent,
    resolve: {user: UserResolver},
    data: {
      signInWithToken: true,
      userState: ['no_password', 'active', 'not_verified', 'unauthorized']
    }
  },
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(router, {
      enableTracing: false,
      preloadingStrategy: CustomPreloadingStrategy
      // onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRouterModule {}
