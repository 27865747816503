import {
  ActivatedRouteSnapshot,
  Route,
  UrlMatcher,
  UrlSegment,
  UrlSegmentGroup
} from '@angular/router';
import {get} from 'lodash';

const multiplePathsMatcher: (paths: string[]) => UrlMatcher = (paths: string[]) => segments =>
  paths.includes(get(segments, '[0].path')) ? {consumed: []} : null;

export function mainModuleMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  return multiplePathsMatcher(['t', 'tasks'])(segments, group, route);
}

export function spacesSwitched(from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot) {
  const getTeamIndex = (snapshot: ActivatedRouteSnapshot) =>
    get(snapshot, 'firstChild.firstChild.params.teamId');
  const currentTeamIndex = getTeamIndex(from);
  const futureTeamIndex = getTeamIndex(to);

  return currentTeamIndex && futureTeamIndex && currentTeamIndex !== futureTeamIndex;
}

export const getCurrentPath = (route: ActivatedRouteSnapshot, navCommands = []) => {
  if (route) {
    navCommands.push(...route.url.map(({path}) => path));

    return getCurrentPath(route.firstChild, navCommands);
  }

  return navCommands;
};
