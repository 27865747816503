import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';

import {RefModelsSharedDaoService} from '../../services/referencemodels/ref-models-shared-dao.service';

@Component({
  selector: 'gdpr-stat-line',
  standalone: true,
  templateUrl: './gdpr-stat-line.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    Ng2GoogleChartsModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule
  ],
  styleUrls: ['./gdpr-stat-line.component.scss']
})
export class GdprStatLineComponent implements OnInit {
  gdprStatusChartConfig;
  isLoading: boolean;

  gdprStatData;

  allChartPeriods = [30, 90, 180, 365, 1825];
  currentChartPeriod = 30;

  headers = [
    {
      id: 'date',
      label: 'Date',
      type: 'date'
    },
    {
      id: 'sensitive-personal-data',
      label: this.translate.instant('REFERENCE_MODELS.SENSETIVE_PERSONAL_DATA'),
      type: 'number'
    },
    {
      id: 'personal-data',
      label: this.translate.instant('REFERENCE_MODELS.PERSONAL_DATA'),
      type: 'number'
    },
    {
      id: 'unassessed-candidate',
      label: this.translate.instant('REFERENCE_MODELS.UNASSESSED_CANDIDATE'),
      type: 'number'
    },
    {
      id: 'unassessed',
      label: this.translate.instant('REFERENCE_MODELS.UNASSESSED'),
      type: 'number'
    },
    {
      id: 'na',
      label: this.translate.instant('REFERENCE_MODELS.NOT_APPLICABLE'),
      type: 'number'
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private refOrgSharedDaoService: RefModelsSharedDaoService
  ) {}

  ngOnInit() {
    this.gdprStatData = this.data.gdprStatData;

    this.gdprStatusChartConfig = {};
    this.gdprStatusChartConfig.dataTable = this.getDataTable(this.gdprStatData);
    this.gdprStatusChartConfig.chartType = 'LineChart';
    this.gdprStatusChartConfig.options = {
      colors: ['#aa0061', '#fa4616', '#0093b2', '#001489', '#00843d'],
      chartArea: {
        top: 10,
        bottom: 40,
        left: 60,
        right: 180
      },
      hAxis: {
        gridlines: {
          count: this.gdprStatData.length < 5 ? this.gdprStatData.length : 5
        },
        format: 'dd MMMM'
      },
      vAxis: {
        baseline: 0,
        gridlines: {
          count: -1
        }
      }
    };
  }

  getDataTable(gdprStatData) {
    const chartRows = this._convertDataForChart(gdprStatData);
    return [this.headers, ...chartRows];
  }

  _convertDataForChart(data) {
    if (!data) {
      return [];
    }

    return _.map(data, (item: any) => {
      const currentTimeZoneDate = new Date(item.date);
      const utcDate = new Date(
        currentTimeZoneDate.getTime() + currentTimeZoneDate.getTimezoneOffset() * 60000
      );

      return [
        {v: utcDate},
        {
          v: item.statuses.sensitivePersonalData,
          f: item.statuses.sensitivePersonalData + ' fields'
        },
        {v: item.statuses.personalData, f: item.statuses.personalData + ' fields'},
        {v: item.statuses.unassessedCandidate, f: item.statuses.unassessedCandidate + ' fields'},
        {v: item.statuses.unassessed, f: item.statuses.unassessed + ' fields'},
        {v: item.statuses.na, f: item.statuses.na + ' fields'}
      ];
    });
  }

  getPeriodTitle(days) {
    switch (days) {
      case 30:
        return this.translate.instant('REFERENCE_MODELS.GDPR_STAT_PERIOD_MONTH');
      case 90:
        return this.translate.instant('REFERENCE_MODELS.GDPR_STAT_PERIOD_90_DAYS');
      case 6 * 30:
        return this.translate.instant('REFERENCE_MODELS.GDPR_STAT_PERIOD_6_MONTH');
      case 365:
        return this.translate.instant('REFERENCE_MODELS.GDPR_STAT_PERIOD_YEAR');
      case 5 * 365:
        return this.translate.instant('REFERENCE_MODELS.GDPR_STAT_PERIOD_5_YEARS');
    }
  }

  onChangeChartPeriod({value}) {
    this.currentChartPeriod = value;
    this.isLoading = true;

    const queryParams = {duration: value};

    return this.refOrgSharedDaoService
      .getGdprStatistic(this.data.refModelId, queryParams)
      .then((res: any) => {
        this.gdprStatData = res;
        this.gdprStatusChartConfig = _.assign({}, this.gdprStatusChartConfig);
        this.gdprStatusChartConfig.options.hAxis.gridlines.count = res.length < 5 ? res.length : 5;
        this.gdprStatusChartConfig.dataTable = this.getDataTable(res);
        this.isLoading = false;
      })
      .catch(function (err) {
        console.log('Failed to get data for chart ', err);
        this.isLoading = false;
      });
  }
}
