import {CommonModule} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {
  ELEMENTS_ISSUE_TYPES,
  Q9DevProjectsSelectComponent,
  Q9HtmlPipesModule
} from '@q9elements/ui-core';
import {filter, get, isEmpty, isNull, isPlainObject} from 'lodash';
import {BehaviorSubject, EMPTY, merge, Observable, of} from 'rxjs';
import {
  catchError,
  finalize,
  map,
  map as mapRx,
  mergeMap,
  repeat,
  takeWhile,
  tap
} from 'rxjs/operators';

import {filterFalsy} from '../../../util/rxjs.util';
import {ImportFromJiraDaoService} from '../../services/import-from-jira/import-from-jira-dao.service';

@Component({
  selector: 'import-from-jira',
  standalone: true,
  templateUrl: './import-from-jira.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    Q9DevProjectsSelectComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    Q9HtmlPipesModule,
    MatProgressSpinnerModule
  ],
  providers: [ImportFromJiraDaoService],
  styleUrls: ['./import-from-jira.component.scss']
})
export class ImportFromJiraComponent implements OnInit, OnDestroy {
  public importIssuesForm: UntypedFormGroup;
  public disableImport: boolean;

  public error$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public inProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showCounter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public inProgressImport$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public jiraIssuesCount$: Observable<{total: number}>;
  public issueTypes$: Observable<any>;

  private aliveObservable = true;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ImportFromJiraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {itemType: string},
    private importFromJiraDaoService: ImportFromJiraDaoService
  ) {}

  ngOnInit() {
    this.createForm();
    this.progressWatch();
    this.onStatusSelect();
    this.subscribeOnFormValuesChanges();
    this.initIssueTypes();
  }

  ngOnDestroy() {
    this.aliveObservable = false;
  }

  private static devProjectValidator(control: AbstractControl) {
    return isNull(control.value) || isPlainObject(control.value) || {invalidDevProject: true};
  }

  private createForm() {
    this.importIssuesForm = this.formBuilder.group({
      devProject: [null, ImportFromJiraComponent.devProjectValidator],
      issueType: [null],
      statuses: [null, Validators.required]
    });
  }

  private progressWatch() {
    this.inProgress$
      .pipe(
        tap(isInProgress => {
          const formStateCb = (!isInProgress && 'enable') || 'disable';

          this.inProgressImport$.next(isInProgress);
          this.importIssuesForm[formStateCb]({emitEvent: false});
        }),
        takeWhile(() => this.aliveObservable)
      )
      .subscribe();
  }

  subscribeOnFormValuesChanges() {
    const controls$ = Object.entries(this.importIssuesForm.controls).map(
      ([controlName, control]) => {
        return control.valueChanges.pipe(mapRx(controlValue => [controlName, controlValue]));
      }
    );

    merge(...controls$)
      .pipe(tap(([controlName, value]) => this.resetControls(controlName)))
      .subscribe();
  }

  getFormControl(controlName) {
    return this.importIssuesForm.get(controlName);
  }

  resetControls(controlName) {
    switch (controlName) {
      case 'issueType':
        this.showCounter$.next(true);
        this.getFormControl('statuses').reset(null, {emitEvent: false, onlySelf: true});
        break;
      case 'devProject':
        this.showCounter$.next(true);
        this.getFormControl('issueType').reset(null, {emitEvent: false, onlySelf: true});
        this.getFormControl('statuses').reset(null, {emitEvent: false, onlySelf: true});
        break;
    }
  }

  initIssueTypes() {
    const selectedElementsIssueType =
      this.data.itemType === 'requirements'
        ? ELEMENTS_ISSUE_TYPES.REQUIREMENT
        : ELEMENTS_ISSUE_TYPES.STORY;
    const getIssueTypes = devProject => get(devProject, 'issueTypes', []);

    this.issueTypes$ = this.getFormControl('devProject').valueChanges.pipe(
      map(devProject =>
        filter(getIssueTypes(devProject), ['elementsIssueType', selectedElementsIssueType])
      )
    );
  }

  private onStatusSelect() {
    this.jiraIssuesCount$ = this.importIssuesForm.get('statuses').valueChanges.pipe(
      tap(() => {
        this.showCounter$.next(true);
        this.error$.next('');
        this.inProgress$.next(true);
      }),
      mergeMap(() => this.getImportEstimates()),
      tap(({total}) => {
        this.disableImport = total < 1;
        this.inProgress$.next(false);
        this.showCounter$.next(false);
      }),
      catchError(error => {
        this.error$.next(error.error);
        this.inProgress$.next(false);
        this.showCounter$.next(false);

        return EMPTY;
      }),
      repeat()
    );
  }

  private get formValue() {
    const {
      devProject: {_id: projectId},
      issueType: {
        jiraIssueType: {id: issueTypeId}
      },
      statuses: statusIds
    } = this.importIssuesForm.getRawValue();

    return {projectId, issueTypeId, statusIds};
  }

  public getImportEstimates() {
    const {projectId, issueTypeId, statusIds} = this.formValue;
    const {itemType} = this.data;

    if (!projectId || isEmpty(statusIds)) {
      return of({total: 0});
    }

    return this.importFromJiraDaoService.getImportEstimates(
      itemType,
      projectId,
      statusIds,
      issueTypeId
    );
  }

  public importStories() {
    const {projectId, issueTypeId, statusIds} = this.formValue;
    const {itemType} = this.data;

    this.error$.next('');
    this.inProgress$.next(true);
    this.showCounter$.next(true);

    this.importFromJiraDaoService
      .runImportIssuesFromJira(itemType, projectId, statusIds, issueTypeId)
      .pipe(
        finalize(() => {
          this.showCounter$.next(true);
          this.inProgress$.next(false);
        }),
        catchError(error => {
          this.error$.next(error.error);
          return of(null);
        }),
        filterFalsy()
      )
      .subscribe(() => this.dialogRef.close());
  }
}
