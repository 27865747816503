<div mat-dialog-title>
  <div class="dialog-title">{{ data.title || 'GENERAL.CONFIRM_DIALOG_TITLE' | translate }}</div>
  <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p>{{ data.text | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    [color]="data.warn ? 'warn' : 'primary'"
    class="q9-btn q9-btn-border"
    tabindex="1"
    mat-dialog-close
  >
    {{ 'GENERAL.CANCEL' | translate }}
  </button>
  <button
    mat-raised-button
    class="q9-btn"
    color="primary"
    tabindex="2"
    [mat-dialog-close]="data"
    cdkFocusInitial
  >
    {{ data.confirm || 'GENERAL.OK' | translate }}
  </button>
</mat-dialog-actions>
