import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {RequestEditRightsComponent} from '../../maps/shared/dialogs/request-edit-rights/request-edit-rights.component';
import {ENTITY_TYPES} from '../../maps/shared/dialogs/request-edit-rights/utils/constants';

import {ShowAllRefModelUsersComponent} from '../components/collapsable-user-list/refmodel/show-all-ref-model-users/show-all-ref-model-users.component';
import {RefModelsSharedDaoService} from '../services/referencemodels/ref-models-shared-dao.service';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
import {CancelReleaseTrainingComponent} from './cancel-release-training/cancel-release-training.component';
import {GdprLegalBasisDonutComponent} from './gdpr-legal-basis-donut/gdpr-legal-basis-donut.component';
import {GdprStatDonutComponent} from './gdpr-stat-donut/gdpr-stat-donut.component';
import {GdprStatLineComponent} from './gdpr-stat-line/gdpr-stat-line.component';
import {ImportFromJiraComponent} from './import-from-jira/import-from-jira.component';
import {RefModelChangeOwnerComponent} from './ref-model-change-owner/ref-model-change-owner.component';
import {RefModelInviteComponent} from './ref-model-invite/ref-model-invite.component';
import {RemindCompleteTrainingComponent} from './remind-complete-training/remind-complete-training.component';
import {SalesForceRoleDialogComponent} from './salesforce-role-dialog/salesforce-role-dialog.component';
import {ChangeStateWarnData} from './state-warn-dialog/change-state-warn-data';
import {ChangeStateWarnComponent} from './state-warn-dialog/change-state-warn.component';
import {TrainingStatisticDialogComponent} from './training-statistic-dialog/training-statistic-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SharedDialogsService {
  constructor(
    private dialog: MatDialog,
    private refOrgSharedDaoService: RefModelsSharedDaoService
  ) {}

  showAlertDialog(errMessage: string): MatDialogRef<AlertDialogComponent> {
    return this.dialog.open(AlertDialogComponent, {
      width: '300px',
      panelClass: ['q9-dialog'],
      data: {
        title: 'GENERAL.ERROR_TITLE',
        text: errMessage
      }
    });
  }

  importFromJira(itemType) {
    return this.dialog
      .open(ImportFromJiraComponent, {
        width: '500px',
        data: {itemType},
        disableClose: true,
        panelClass: ['q9-dialog']
      })
      .afterClosed();
  }

  openRemindCompleteTrainingDialog(params): MatDialogRef<RemindCompleteTrainingComponent> {
    return this.dialog.open(RemindCompleteTrainingComponent, {
      width: '500px',
      panelClass: ['q9-dialog'],
      data: params
    });
  }

  openSFRolePickerDialog(): MatDialogRef<SalesForceRoleDialogComponent> {
    return this.dialog.open(SalesForceRoleDialogComponent, {
      width: '500px',
      panelClass: ['q9-dialog']
    });
  }

  openCancelReleaseTrainingComponent(params): MatDialogRef<CancelReleaseTrainingComponent> {
    return this.dialog.open(CancelReleaseTrainingComponent, {
      width: '400px',
      panelClass: ['q9-dialog', 'q9-dialog-danger'],
      data: params
    });
  }

  openTrainingStatistic(daoService, taskId): MatDialogRef<TrainingStatisticDialogComponent> {
    return this.dialog.open(TrainingStatisticDialogComponent, {
      width: '800px',
      panelClass: ['q9-dialog'],
      data: {
        taskId,
        daoService
      }
    });
  }

  changeStatusWarn(data: ChangeStateWarnData): Observable<any> {
    return this.dialog
      .open(ChangeStateWarnComponent, {
        width: '400px',
        panelClass: ['q9-dialog', 'q9-dialog-danger'],
        disableClose: true,
        data
      })
      .beforeClosed();
  }

  changeOwner(row): MatDialogRef<RefModelChangeOwnerComponent> {
    return this.dialog.open(RefModelChangeOwnerComponent, {
      width: '400px',
      panelClass: ['q9-dialog'],
      data: row
    });
  }

  showAllUsers(row, refModelRole): MatDialogRef<ShowAllRefModelUsersComponent> {
    return this.dialog.open(ShowAllRefModelUsersComponent, {
      width: '400px',
      panelClass: ['q9-dialog'],
      data: {row: row, refModelRole: refModelRole}
    });
  }

  refModelInvite(row, userId: string): MatDialogRef<RefModelInviteComponent> {
    return this.dialog.open(RefModelInviteComponent, {
      width: '500px',
      panelClass: ['q9-dialog'],
      data: {row, presetUserId: userId}
    });
  }

  openGdprStatLineModal(row): any {
    const queryParams = {
      duration: 30
    };

    return this.refOrgSharedDaoService.getGdprStatistic(row.id, queryParams).then(res => {
      const dialog = this.dialog.open(GdprStatLineComponent, {
        width: '600px',
        panelClass: ['q9-dialog'],
        autoFocus: false,
        data: {
          gdprStatData: res,
          refModelId: row.id
        }
      });

      return dialog.afterClosed().subscribe();
    });
  }

  openGdprStatDonutModal(
    gdprStatData,
    gdprStatusChartConfig,
    gdprStates
  ): MatDialogRef<GdprStatDonutComponent> {
    return this.dialog.open(GdprStatDonutComponent, {
      width: '600px',
      panelClass: ['q9-dialog'],
      autoFocus: false,
      data: {gdprStatData, gdprStatusChartConfig, gdprStates}
    });
  }

  openGdprLegalBasisDonutModal(metadata): MatDialogRef<GdprLegalBasisDonutComponent> {
    return this.dialog.open(GdprLegalBasisDonutComponent, {
      width: '600px',
      panelClass: ['q9-dialog'],
      autoFocus: false,
      data: metadata
    });
  }

  requestEditRights(row): MatDialogRef<RequestEditRightsComponent> {
    return this.dialog.open(RequestEditRightsComponent, {
      width: '400px',
      panelClass: ['q9-dialog'],
      data: {row, entityType: ENTITY_TYPES.REFERENCE_MODELS}
    });
  }
}
