import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SfCallbackReasonResolver {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot) {
    return route.url.map(s => s.path).includes('connect') ? 'connection' : 'auth';
  }
}
