import * as _ from 'lodash';

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';

import {catchError} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {throwError} from 'rxjs';
import {Q9ParamsInterface, Q9UserService} from '@q9elements/ui-core';

@Injectable({
  providedIn: 'root'
})
export class TagsDaoService {
  apiUrl: string;

  constructor(private http: HttpClient, private q9UserService: Q9UserService) {
    this.apiUrl = environment.API_ENDPOINT;
  }

  getTags(limit?, exclude = []) {
    const teamId = this.q9UserService.getTeamId();
    const Params = this.composeRequestParams({
      limit: limit || '',
      excludeTags: exclude ? exclude.join() : ''
    });

    return this.http.get(`${this.apiUrl}/organizations/${teamId}/tags`, {params: Params});
  }

  getTagsByName(word = '') {
    const teamId = this.q9UserService.getTeamId();
    return this.http
      .get(`${this.apiUrl}/organizations/${teamId}/tags/${word}`)
      .pipe(catchError(this.handleError));
  }

  createTag(tag: string) {
    const teamId = this.q9UserService.getTeamId();
    return this.http
      .post(`${this.apiUrl}/organizations/${teamId}/tags`, {word: tag})
      .pipe(catchError(this.handleError));
  }

  private composeRequestParams(params: Q9ParamsInterface): HttpParams {
    // Initialize Params Object
    let Params = new HttpParams();
    // Assigning parameters
    _.forIn(params, function (value, key) {
      Params = Params.append(key, `${value}`);
    });
    return Params;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
