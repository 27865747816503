import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FirebaseService {
  constructor(private afAuth: AngularFireAuth, private fb: AngularFireDatabase) {}

  authenticate(token): Promise<any> {
    return this.afAuth.signInWithCustomToken(token);
  }

  unauth() {
    return this.afAuth.signOut();
  }

  subscribeForTask(ref): Observable<any> {
    return this.fb.object(ref).snapshotChanges();
  }

  removeTaskFromQueue(ref): Promise<any> {
    return this.fb.object(ref).remove();
  }

  subscribeToSyncProgress(refModelId: string, lastSyncUUID: string, callback: any) {
    const path = `/referenceModels/${refModelId}/sync/${lastSyncUUID}`;
    return this.fb.database.ref(path).on('value', callback);
  }

  unsubscribeFromSyncProgress(refModelId, syncUUID) {
    const path = `/referenceModels/${refModelId}/sync/${syncUUID}`;
    this.fb.database.ref(path).off();
  }
}
