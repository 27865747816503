<div mat-dialog-title>
  <div class="dialog-title">
    {{ 'REFERENCE_MODELS.CHANGE_OWNER_CHANGE_REFMODEL_OWNER' | translate }}
  </div>
  <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="q9-modal-error" *ngIf="error">
  <div class="text">{{ error }}</div>
</div>
<form class="example-form" (ngSubmit)="submit()">
  <mat-dialog-content>
    <mat-form-field
      class="q9-form-field q9-full-width"
      [floatLabel]="'always'"
      appearance="outline"
    >
      <mat-label>{{ 'REFERENCE_MODELS.CHANGE_OWNER_MODAL_LABEL' | translate }}</mat-label>
      <input
        matInput
        required
        minlength="2"
        cdkFocusInitial
        (focus)="error = null"
        [matAutocomplete]="auto"
        [formControl]="searchControl"
        [errorStateMatcher]="this"
      />

      <mat-hint>{{ 'REFERENCE_MODELS.CHANGE_OWNER_EMAIL_OR_NAME' | translate }}</mat-hint>

      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="onSelectHandler($event)"
        autoActiveFirstOption="true"
      >
        <mat-option *ngFor="let user of users$ | async" [value]="user">
          <q9-user-info class="q9-user-info candidate" [user]="user"></q9-user-info>
        </mat-option>
      </mat-autocomplete>

      <mat-error class="error-message" *ngIf="matError">
        <mat-icon svgIcon="forbidden"></mat-icon>
        {{ matError | translate }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="primary" class="q9-btn q9-btn-border" tabindex="1" mat-dialog-close>
      {{ 'REFERENCE_MODELS.CHANGE_OWNER_CANCEL' | translate }}
    </button>
    <button
      mat-raised-button
      class="q9-btn"
      color="primary"
      tabindex="2"
      type="submit"
      cdkFocusInitial
    >
      {{ 'REFERENCE_MODELS.CHANGE_OWNER_CHANGE' | translate }}
    </button>
  </mat-dialog-actions>
</form>
