import {Injectable} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Q9maCustomFieldsDaoService} from '@q9elements/ma';

import {Q9SnackBarService} from '@q9elements/ui-core';

import {cloneDeep, get} from 'lodash';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {filter, map} from 'rxjs/operators';
import {UserSettingsService} from '../../../../_main/services/user-settings.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {enumerable} from '../../../../util/enum';
import {filterFalsy} from '../../../../util/rxjs.util';
import {SharedStoreService} from '../../../services/shared-store/shared-store.service';
import {TagsDaoService} from '../../tags/tags-dao.service';
import {AnyPropertiesObject} from '../models/any-properties-object';
import {IBulkActions} from '../models/bulkActions.interface';
import {EditEvent} from '../models/q9-grid-edit.interface';

import {
  CloseGridRowReason,
  ColumnConfig,
  GetGridDataReason,
  GridEditTemplate,
  SelectedRowInfo,
  SelectedRowOptions
} from '../models/q9-grid.interface';

@Injectable({
  providedIn: 'root'
})
export class Q9GridService {
  private refreshGridDataSubject: BehaviorSubject<GetGridDataReason> =
    new BehaviorSubject<GetGridDataReason>(false);
  refreshGridData$: Observable<GetGridDataReason> = this.refreshGridDataSubject
    .asObservable()
    .pipe(filterFalsy()) as Observable<GetGridDataReason>;

  closeGridRowSubject: Subject<CloseGridRowReason> = new Subject<CloseGridRowReason>();

  private selectedRowInfoSubject: BehaviorSubject<SelectedRowInfo> =
    new BehaviorSubject<SelectedRowInfo>({options: {triggerNext: false}});
  selectedRowInfo$: Observable<SelectedRowInfo> = this.selectedRowInfoSubject.asObservable();
  selectedRow$: Observable<any> = this.selectedRowInfo$.pipe(
    filter(info => info.options.triggerNext),
    map(rowInfo => rowInfo.selectedRow)
  );

  private markForCheckSubject: Subject<void> = new Subject<void>();
  markForCheck$: Observable<void> = this.markForCheckSubject.asObservable();

  editRowSubject: Subject<EditEvent> = new Subject<EditEvent>();
  bulkActionsSubject: Subject<IBulkActions> = new Subject<IBulkActions>();

  private gridDataSubject: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>(null);
  gridData$: Observable<Array<any>> = this.gridDataSubject.asObservable();

  private selectedKeysSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  selectedKeys$ = this.selectedKeysSubject.asObservable();

  private totalItemsSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  totalItems$ = this.totalItemsSubject.asObservable();

  private skipPageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  skipPage$ = this.skipPageSubject.asObservable();

  private pageSizeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(100);
  pageSize$ = this.pageSizeSubject.asObservable();

  private pageIndexSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageIndex$ = this.pageIndexSubject.asObservable();

  private disabledPaginatorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  disablePaginator$ = this.disabledPaginatorSubject.asObservable();

  private columnsSubject: BehaviorSubject<ColumnConfig[]> = new BehaviorSubject<ColumnConfig[]>([]);
  columns$ = this.columnsSubject.asObservable();

  constructor(
    private tagsDaoService: TagsDaoService,
    private customFieldsDaoService: Q9maCustomFieldsDaoService,
    private translate: TranslateService,
    private authService: AuthService,
    private snackBarService: Q9SnackBarService,
    private sharedStore: SharedStoreService,
    private userSettingsService: UserSettingsService
  ) {
    this.sharedStore
      .getSubscription('notification$')
      .pipe(
        filter(notification => {
          return ['REQUIREMENT', 'STORY'].includes(get(notification, 'type'));
        })
      )
      .subscribe(() => this.refreshGrid());
  }

  set selectedKeys(keys) {
    this.selectedKeysSubject.next(keys);
  }

  get selectedKeys() {
    return this.selectedKeysSubject.value;
  }

  set columns(columns: ColumnConfig[]) {
    this.columnsSubject.next(columns);
  }

  get columns(): ColumnConfig[] {
    return this.columnsSubject.value;
  }

  set totalItems(items: number) {
    this.totalItemsSubject.next(items);
  }

  get totalItems() {
    return this.totalItemsSubject.value;
  }

  set skipPage(page: number) {
    this.skipPageSubject.next(page);
  }

  get skipPage() {
    return this.skipPageSubject.value;
  }

  set pageSize(pageSize: number) {
    this.pageSizeSubject.next(pageSize);
  }

  get pageSize() {
    return this.pageSizeSubject.value;
  }

  set pageIndex(pageIndex: number) {
    this.pageIndexSubject.next(pageIndex);
  }

  get pageIndex() {
    return this.pageIndexSubject.value;
  }

  set disablePaginator(condition: boolean) {
    this.disabledPaginatorSubject.next(condition);
  }

  get disablePaginator() {
    return this.disabledPaginatorSubject.value;
  }

  selectAll() {
    const amountOfItemOnLastPage = this.pageSize + this.skipPage;
    let lastElement;
    let lastElementOnCurrentPage;
    let firstElement;
    let firstElementOnCurrentPage;

    if (!(this.pageSize > this.gridData.length || amountOfItemOnLastPage > this.totalItems)) {
      firstElement = this.gridData[this.pageIndex * this.pageSize];
      firstElementOnCurrentPage = this.gridData.findIndex(item => item.id === firstElement.id);
      lastElement = this.gridData[this.pageSize + this.skipPage - 1];
      lastElementOnCurrentPage = this.gridData.findIndex(item => item.id === lastElement.id) + 1;
    } else {
      firstElement = this.gridData[this.pageIndex * this.pageSize];
      firstElementOnCurrentPage = this.gridData.findIndex(item => item.id === firstElement.id);
      lastElement = this.gridData[this.gridData.length - 1];
      lastElementOnCurrentPage = this.gridData.findIndex(item => item.id === lastElement.id) + 1;
    }
    const selectAllOnCurrentPage = this.gridData.slice(
      firstElementOnCurrentPage,
      lastElementOnCurrentPage
    );

    if (selectAllOnCurrentPage.length === 1) {
      this.setSelectedRow(selectAllOnCurrentPage[0]);
    }

    this.selectedKeys = selectAllOnCurrentPage.map(item => item.id);
  }

  unselectAll() {
    this.selectedKeys = [];
  }

  refreshGrid(reason: GetGridDataReason = true) {
    this.selectedKeys = [];
    this.setDefaultPaginatorValue();
    this.refreshGridDataSubject.next(reason);
  }

  updateSelectedRow(id: string, props: AnyPropertiesObject, triggerNext = true): void {
    if (!this.gridData) return void 0;

    const foundRow = this.gridData.find(gridRow => gridRow.id === id);

    if (!foundRow) return void 0;

    Object.assign(foundRow, props);

    this.triggerGridViewUpdate();

    if (triggerNext) {
      this.setSelectedRow(foundRow, {triggerNext});
    }
  }

  setSelectedRow(selectedRow: any, options: SelectedRowOptions = {triggerNext: true}) {
    this.selectedRowInfoSubject.next({selectedRow, options});

    // reset trigger for initial subscribers
    this.selectedRowInfoSubject.value.options.triggerNext = true;
  }

  addNewRow(rowData) {
    const gridData = this.gridData;

    gridData.unshift(rowData);
    this.gridDataSubject.next(gridData);
    this.totalItems = this.totalItems + 1;
    this.pageIndex = 0;
    this.skipPage = this.pageIndex * this.pageSize;
    this.gridData = this.gridData;
  }

  removeRow(row: any) {
    const lastElementDeleted = this.gridData.length === 1;

    if (lastElementDeleted) return this.refreshGrid();

    this.gridDataSubject.next(this.gridData.filter(({id}) => id !== row.id));
    this.selectedKeys = [];
    this.totalItems = this.totalItems - 1;

    const total = this.totalItems;
    const page = this.skipPage / this.pageSize + 1;
    const itemsPrePage = this.pageSize;
    const lastElementOnPage = page * itemsPrePage - total;

    if (lastElementOnPage === itemsPrePage) {
      this.pageIndex = this.pageIndex - 1;
      this.skipPage = this.pageIndex * this.pageSize;
      this.gridData = this.gridData;
    }
  }

  get getCurrentItemsOnGrid() {
    const startCurrentItems = this.pageSize + this.skipPage - this.pageSize;
    const endCurrentItems = this.pageSize + this.skipPage;

    return this.gridData.slice(startCurrentItems, endCurrentItems);
  }

  updateOrAddColumn(customField, customFieldsToPush, columns: ColumnConfig[]) {
    const customFieldId = customField.id;
    const customFieldTitle = customField.title;
    const customFieldPlaceholder = customField.placeholder;
    const customFieldType = customField.properties.type;
    const required: boolean = customField.required;
    const customFieldColumnIndex = columns.findIndex(
      column => column.controlName === customFieldId
    );
    const columnExists = customFieldColumnIndex !== -1;
    const customFieldColumn: any = columns[customFieldColumnIndex] || {};

    Object.assign(customFieldColumn, {
      controlName: customFieldId,
      fieldPath: customFieldId,
      title: customFieldTitle,
      placeholder: customFieldPlaceholder,
      editTemplate: customFieldType,
      isCustomField: true,
      order: customField.position
    });

    switch (customFieldType) {
      case GridEditTemplate.text:
        Object.assign(customFieldColumn, {
          validators: {
            required,
            minLength: required ? 2 : 0,
            maxLength: 80
          },
          error: {
            message: 'GENERAL.CHAR_LENGTH_ERROR',
            interpolateParams: {min: required ? 2 : 0, max: 80}
          }
        });
        break;
      case GridEditTemplate.textarea:
        Object.assign(customFieldColumn, {
          validators: {
            required,
            minLength: required ? 2 : 0,
            maxLength: 32000
          },
          error: {
            message: 'GENERAL.CHAR_LENGTH_ERROR',
            interpolateParams: {min: required ? 2 : 0, max: 32000}
          }
        });
        break;
      case GridEditTemplate.numeric:
        const min = customField.properties.min;
        const max = customField.properties.max;

        Object.assign(customFieldColumn, {
          validators: {required, min, max},
          error: {
            message: 'CUSTOM_FIELDS.INVALID_NUMBER_VALUE',
            interpolateParams: {min, max}
          }
        });
        break;
      case GridEditTemplate.datepicker:
        Object.assign(customFieldColumn, {
          validators: {
            required,
            minLength: required ? 2 : 0,
            maxLength: 32000
          },
          error: {
            message: 'CUSTOM_FIELDS.INVALID_DATE_FIELD'
          }
        });
        break;
      case GridEditTemplate.dropdown:
        const options = customField.properties.options;

        Object.assign(customFieldColumn, {
          field: 'value',
          fieldPath: `${customFieldColumn.controlName}.value`,
          dropdownValueField: '_id',
          dropdownOptions: options,
          placeholder: customFieldPlaceholder || enumerable.defaultCustomDropdownPlaceholder,
          validators: {required},
          transform: option => {
            if (!option) {
              return '';
            } else {
              return option.id === null ? '' : option.value;
            }
          },
          error: {
            message: 'CUSTOM_FIELDS.INVALID_LIST_FIELD'
          }
        });

        if (customField.properties.predefined) {
          Object.assign(customFieldColumn, {defaultValue: options[0]});
        }

        break;
      case GridEditTemplate.multiselect:
        Object.assign(customFieldColumn, {
          controlOptionsEndPoint: (search = '') =>
            of(customField.properties.tags.filter(tag => tag.includes(search))),
          transform: Q9GridService.transformTags,
          validators: {required}
        });

        if (required) {
          customFieldColumn.validators.customValidator = (control: UntypedFormControl) =>
            get(control, 'value.length') ? null : {emptyArray: 'CUSTOM_FIELDS.INVALID_LIST_FIELD'};
        }
    }

    if (!columnExists) {
      customFieldColumn.width = this.getHeaderWidth(customFieldColumn, false);
      customFieldsToPush.push(customFieldColumn);
    }
  }

  getHeaderWidth(column: ColumnConfig, translate = true): number {
    if (column.width) {
      return column.width;
    }

    const widthFromFirestore = this.userSettingsService.getColumnWidth(column.controlName);

    if (widthFromFirestore) {
      return widthFromFirestore;
    }
    if (column.editTemplate === GridEditTemplate.textarea) {
      return enumerable.defaultTextAreaColumnHeaderWidth;
    }

    const headerEl = document.createElement('div');

    headerEl.style.position = 'fixed';
    headerEl.style.opacity = '0';
    headerEl.style.font = '12px "Source Sans Pro", sans-serif';
    headerEl.style.fontWeight = '700';
    headerEl.innerText = translate ? this.translate.instant(column.title) : column.title;

    const headerWidth = document.body.appendChild(headerEl).clientWidth + 10 + 37 + 20;

    document.body.removeChild(headerEl);

    return (column.headerWidthMultiplier || 1) * headerWidth;
  }

  removeGridRow = (removeGridRow: (row) => Observable<any>) => {
    return gridRow => {
      this.sharedStore.set('gridLoading', true);
      removeGridRow(gridRow)
        .pipe(filterFalsy())
        .subscribe(
          () => this.removeRow(gridRow),
          reject => {
            const error = get(reject, 'error');

            this.sharedStore.set('gridLoading', false);
            error && this.snackBarService.show(error);
          },
          () => this.sharedStore.set('gridLoading', false)
        );
    };
  };

  set gridData(gridData) {
    this.gridDataSubject.next(gridData);
  }

  get gridData() {
    return this.gridDataSubject.value;
  }

  get selectedRow() {
    return this.selectedRowInfoSubject.value.selectedRow;
  }

  static transformStatus(value, encodeHtml) {
    return `<div class='colorful-cell'>
                  <span class='colorful-cell__status-label' style='color:${encodeHtml(
                    value.color
                  )}'>
                  ${encodeHtml(value.title)}
               </span>
               </div>`;
  }

  static transformTags(value: any[], encodeHtml): string {
    if (!value) {
      return '';
    }

    return `<div>${value.reduce(
      (acc, tag) => (acc += `<div class='tag-list-item'>${encodeHtml(tag)}</div>`),
      ''
    )}</div>`;
  }

  static sortColumns(columns: ColumnConfig[]) {
    return columns.sort((columnOne, columnTwo) => {
      const columnOneIsCustomField = columnOne.isCustomField;
      const columnTwoIsCustomField = columnTwo.isCustomField;
      const bothCustomFields = columnOneIsCustomField && columnTwoIsCustomField;
      const bothNotCustomFields = !columnOneIsCustomField && !columnTwoIsCustomField;

      switch (true) {
        case bothCustomFields:
        case bothNotCustomFields:
          return columnOne.order - columnTwo.order;
        case columnOneIsCustomField:
          return -columnTwo.order;
        default:
          return columnOne.order;
      }
    });
  }

  private triggerGridViewUpdate() {
    this.gridData = cloneDeep(this.gridData);
    this.markForCheckSubject.next();
  }

  setDefaultPaginatorValue() {
    this.pageIndex = 0;
    this.skipPage = this.pageIndex * this.pageSize;
    this.gridData = this.gridData;
  }
}
