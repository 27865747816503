<div class="user-permission form-group" [ngClass]="{'user-permission_loading': isLoading}">
  <div class="container">
    <div class="row">
      <div class="col-7 user-section">
        <q9-user-info class="q9-user-info" [enableTooltip]="true" [user]="user"> </q9-user-info>
      </div>
      <div class="col-4 user-role">
        <mat-form-field
          class="q9-form-field q9-full-width q9-without-label q9-form-field-filter"
          [floatLabel]="'never'"
          appearance="outline">
          <mat-select
            panelClass="q9-select"
            q9Track="USER.PERMISSIONS_SELECT"
            (selectionChange)="changeRefModelUserRole($event, user)"
            [disabled]="!canChangeRole(user) || isViewerInTeam(user)"
            [value]="user.refModelRole">
            <mat-option
              class="q9-select-option"
              *ngFor="let option of userPermissionOptions"
              [q9Track]="option.title"
              [value]="option.value"
              >{{ option.title | translate }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-1 remove-permission-container">
        <div
          class="remove-item"
          q9Track="USER.REMOVE_BTN"
          [hidden]="!isManager && !isMe(user)"
          (click)="deleteUserFromRefModel(user)">
          <mat-icon svgIcon="close"></mat-icon>
        </div>
      </div>
    </div>

    <div class="row receive-updates" *ngIf="this.isExternalRefModel">
      <div class="col-8">
        <mat-checkbox
          [disabled]="!this.isManager"
          color="primary"
          class="q9-checkbox"
          *ngIf="!processingChange"
          q9Track="USER.RECEIVE_CHANGES_EMAIL"
          [(ngModel)]="user.sendEmailAboutChanges"
          (change)="receiveUpdatesChange($event, user)">
          {{ 'USER.RECEIVE_CHANGES_EMAIL' | translate }}
        </mat-checkbox>
        <ng-container *ngIf="processingChange">
          <mat-spinner strokeWidth="1" diameter="20"></mat-spinner>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="spinner-overlay" *ngIf="isLoading">
    <mat-spinner strokeWidth="1" diameter="20"></mat-spinner>
  </div>
</div>
