import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';

import {GdprLegalBasisStatusChartConfig} from '../../components/gdpr-statistic/gdpr-legal-basis-chart';
import {KeysPipe} from '../../pipes/keys/keys.pipe';
import {RefModelsSharedDaoService} from '../../services/referencemodels/ref-models-shared-dao.service';

const modalChartOptions = {
  enableInteractivity: true,
  textStyle: {
    fontSize: 14
  },
  chartArea: {
    height: '90%',
    left: 0
  }
};

@Component({
  selector: 'gdpr-legal-basis-donut',
  standalone: true,
  templateUrl: './gdpr-legal-basis-donut.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    Ng2GoogleChartsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    KeysPipe
  ],
  styleUrls: ['./gdpr-legal-basis-donut.component.scss']
})
export class GdprLegalBasisDonutComponent implements OnInit {
  gdprLegalBasisStates;
  gdprStatData;
  gdprStatusChartConfig;
  purposeList;
  currentChartPurpose;
  isLoading: boolean;
  chartReady: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private refOrgSharedDaoService: RefModelsSharedDaoService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<GdprLegalBasisDonutComponent>
  ) {}

  ngOnInit() {
    this.gdprLegalBasisStates = this.data.gdprLegalBasisStates;
    this.gdprStatData = this.data.gdprStatData;
    this.gdprStatusChartConfig = this.data.gdprStatusChartConfig;
    this.currentChartPurpose = this.data.currentChartPurpose;
    this.purposeList = this.data.purposeList;
    this.gdprStatusChartConfig.updateWithOptions(modalChartOptions);
  }

  onChangeChartPurpose(purpose) {
    const queryParams = {
      type: 'legal-basis',
      purposeId: purpose._id
    };

    this.isLoading = true;
    this.currentChartPurpose = purpose;

    return this.refOrgSharedDaoService
      .getGdprStatistic(this.data.refModelId, queryParams)
      .then((res: {stat: any; purposes: any; currentPurpose: any}) => {
        this.purposeList = res.purposes;
        this.currentChartPurpose = _.assign({}, res.currentPurpose);

        this.gdprStatData = res.stat;
        this.gdprStatData.allGdprNodes = _.chain(this.gdprStatData).values().sum().value();

        this.gdprStatusChartConfig = new GdprLegalBasisStatusChartConfig()
          .setupConfig(this.translate, this.gdprStatData)
          .updateWithOptions(modalChartOptions);

        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        console.log('Failed to get GDPR statistic data: ', err);
      });
  }
}
