<div mat-dialog-title>
  <div class="dialog-title">{{ 'REFERENCE_MODELS.GDPR_STAT_LINE' | translate }}</div>
  <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>

<mat-dialog-content class="dialog-content">
  <div class="chart-period-wrapper">
    <mat-form-field
      class="q9-form-field q9-hide-label q9-without-label"
      [floatLabel]="'never'"
      appearance="outline"
    >
      <mat-select
        panelClass="q9-select"
        (selectionChange)="onChangeChartPeriod($event)"
        [disabled]="isLoading"
        [value]="currentChartPeriod"
      >
        <mat-option class="q9-select-option" *ngFor="let period of allChartPeriods" [value]="period"
          >{{ getPeriodTitle(period) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="gdpr-line-chart" *ngIf="gdprStatData.length">
    <google-chart [data]="gdprStatusChartConfig"></google-chart>
  </div>

  <div class="empty-msg" *ngIf="gdprStatData.length < 1">
    {{ 'REFERENCE_MODELS.NO_STAT_DATA_FOR_PERIOD_MSG' | translate }}
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="primary" class="q9-btn q9-btn-border" tabindex="1" mat-dialog-close>
    {{ 'GENERAL.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
