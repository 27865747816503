<div mat-dialog-title>
  <div class="dialog-title">{{ 'TRAININGS.CANCEL_TRAINING_TITLE' | translate }}</div>
  <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="q9-modal-error" *ngIf="error">
  <div class="text">{{ error }}</div>
</div>
<mat-dialog-content>
  <div class="content-spinner" *ngIf="inProgress && !error">
    <mat-spinner></mat-spinner>
  </div>

  <div class="action-message" *ngIf="!inProgress">
    <p>{{ 'TRAININGS.CANCEL_TRAINING_MESSAGE' | translate }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="warn" class="q9-btn q9-btn-border" tabindex="1" mat-dialog-close>
    {{ 'GENERAL.CLOSE' | translate }}
  </button>
  <button
    mat-raised-button
    class="q9-btn"
    color="primary"
    tabindex="2"
    [disabled]="inProgress"
    (click)="cancelReleaseTraining()"
    cdkFocusInitial
  >
    {{ 'GENERAL.CANCEL' | translate }}
  </button>
</mat-dialog-actions>
