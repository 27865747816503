import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.API_ENDPOINT;
  }

  remindCompleteTraining(params) {
    const {id, message, requiredBy, type} = params;
    const data = {message, requiredBy};
    return this.http
      .put(`${this.apiUrl}/trainings/${id}/remind/${type}`, data)
      .pipe(catchError(this.handleError));
  }

  cancelReleaseTraining(params) {
    const {id, type} = params;
    return this.http
      .delete(`${this.apiUrl}/trainings/${id}/cancel/${type}`)
      .pipe(catchError(this.handleError));
  }

  countTrainingUsers(params) {
    const {id, type} = params;

    return this.http
      .get(`${this.apiUrl}/trainings/${id}/users/count/${type}`)
      .pipe(catchError(this.handleError));
  }

  countNotFinishedTrainingUsers(params) {
    const {id, type} = params;
    return this.http
      .get(`${this.apiUrl}/trainings/${id}/users/count/unfinished/${type}`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
