export const environment: any = {
    version: require('../../package.json').version,
    VERSION: require('../../package.json').version,
    API_ENDPOINT: 'https://apc10-api.elements-eng.cloud:38443/api/v1',
    API_URL: 'https://apc10-api.elements-eng.cloud:38443/api/v1/datastructures',
    API_STATIC_FILE_UPLOAD: 'https://eng-upload.q9elements.com:11443/api',
    APPNAME: 'HD-APP',
    ANALYSIS_API: 'https://dev-gateway.q9elements.com/automation-us-dependency-analysis/api/v1/dependency-analysis',
    CHANGELOG_APP_URL: 'https://apc10-changelogs.elements-eng.cloud',
    CLOUD_NAME: 'elements',
    CLOUD_TYPE: 'public',
    DATA_STRUCTURE_URL: 'https://apc10-datatable.elements-eng.cloud',
    DE_APP_URL: 'https://apc10-diagram.elements-eng.cloud',
    DE_APP: 'https://apc10-diagram.elements-eng.cloud',
    DIAGRAM_URL: 'https://apc10-diagram.elements-eng.cloud',
    DOMAIN: 'elements-eng.cloud',
    ENTERPRISE_URL: 'https://apc10-corp.elements-eng.cloud',
    ENV: 'apc10',
    GDPR_SUPPORT_LINK: 'https://support.elements.cloud/en/articles/1221094-enable-data-privacy-gdpr-compliance-for-a-ref-model',
    HELP_LINK: 'https://support.elements.cloud/',
    IMPORT_MAP_API: 'https://dev-gateway.q9elements.com/apc10-map-import/api/v1',
    IMPORTS_API: 'https://dev-gateway.q9elements.com/apc10-import/api/v1',
    INTERCOM_APP_ID: 'sfi4u1en',
    INTERCOM_URL: 'string',
    MAIN_APP_URL: 'https://apc10.elements-eng.cloud',
    MAIN_APP: 'https://apc10.elements-eng.cloud',
    MAP_MANAGEMENT_APP: 'https://apc10-mapmgt.elements-eng.cloud',
    NO_IMAGE_URL: '../../assets/images/no_image.png',
    NOUI: false,
    PARTNER_APP: 'https://apc10-partnermgt.elements-eng.cloud',
    PREVIEW_HOLDER: '/assets/images/map_placeholder.png',
    production: true,
    PX_KEY: 'AP-3EN4L67VSTXG-2-3',
    RAVEN_DSN: 'https://eb6892047d9b42638a7563ddb26386ea@sentry.io/1233470',
    RAVEN_ID: '9e76fe14aaba4a31846fe607572f9eb1',
    REF_MODEL_APP: 'https://apc10-refmodel.elements-eng.cloud',
    RELEASE_URL: 'https://apc10-releasemgt.elements-eng.cloud',
    REMOTE_UNLOCK_TTL: 3600000,
    REPORTS_API_ENDPOINT: 'https://dev-gateway.q9elements.com/apc10-report/api/v1',
    REPORTS_API: 'https://dev-gateway.q9elements.com/apc10-report/api/v1', 
    REPORTS_APP: 'https://apc10-report.elements-eng.cloud',
    SALESFORCE_CLIENT_ID: '3MVG9XLfsJ3MQLvwvVWbppMl4vkcDr3N8V3jokmlxYLsKy4FOjytwmk2kSEa.Z_se_fpayO8j5vLOE5j2B4Fz',
    SALESFORCE_REDIRECT_URL: 'https://apc10.elements-eng.cloud/auth/callback',
    SALESFORCE_URL_TEST: 'https://test.salesforce.com/services/oauth2/authorize',
    SALESFORCE_URL: 'https://login.salesforce.com/services/oauth2/authorize',
    SECURE_COOKIES: true,
    SYS_ADMIN_APP: 'https://apc10-sysadmin.elements-eng.cloud',
    TEAM_APP: 'https://apc10-spacemgt.elements-eng.cloud',
    TOASTR_TIMEOUT: 5000,
    UNLOCK_TTL: 180000,
    STATIC_FILES_URL: 'https://static.elements.cloud',
    fbConfig: {
        apiKey:'AIzaSyDysCRZ55fZtHruWkp4yh7yOjld1TpX_LY',
        authDomain:'elements-apc10-ysdc.firebaseapp.com',
        databaseURL: 'https://elements-apc10-ysdc-default-rtdb.firebaseio.com',
        projectId: 'elements-apc10-ysdc',
        appId: '1:611995281137:web:6bbbccb5d1f70bb9f4eb50'
    }
}; 
