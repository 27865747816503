import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, fromEvent} from 'rxjs';
import {debounceTime, map, skip, takeWhile, tap} from 'rxjs/operators';
import {startWith} from 'rxjs/operators';

import {getUserStatisticListConfig} from './training-statisctic.util';

interface TrainingStatisticInputInterface {
  type: 'completed' | 'notCompleted';
  taskId: string;
  daoService: any;
}

@Component({
  selector: 'training-statistic-dialog',
  templateUrl: 'training-statistic-dialog.component.html',
  styleUrls: ['training-statistic-dialog.component.scss']
})
export class TrainingStatisticDialogComponent implements OnInit, OnDestroy {
  @ViewChild('filterByUser', {static: true}) filterByUser;
  @ViewChild('list') list;

  trainingStatusControl: UntypedFormControl;

  aliveObservable = true;
  dataSource = new MatTableDataSource();
  listConfig;

  trainingStatuses = [
    {value: 'all', label: this.translate.instant('TRAININGS.TRAINING_STATUS_LABEL_ALL')},
    {
      value: 'notStarted',
      label: this.translate.instant('TRAININGS.TRAINING_STATUS_LABEL_NOT_STARTED')
    },
    {
      value: 'inProgress',
      label: this.translate.instant('TRAININGS.TRAINING_STATUS_LABEL_IN_PROGRESS')
    },
    {
      value: 'readyToApprove',
      label: this.translate.instant('TRAININGS.TRAINING_STATUS_LABEL_READY_TO_ACKNOWLEDGE')
    },
    {
      value: 'completed',
      label: this.translate.instant('TRAININGS.TRAINING_STATUS_LABEL_ACKNOWLEDGED')
    },
    {value: 'cancelled', label: this.translate.instant('TRAININGS.TRAINING_STATUS_LABEL_CANCELLED')}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TrainingStatisticInputInterface,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.trainingStatusControl = new UntypedFormControl('all');

    this.listConfig = getUserStatisticListConfig.call(this, this.data.taskId);
    this.setupUserInputObserver();
  }

  ngOnDestroy() {
    this.aliveObservable = false;
  }

  setupUserInputObserver() {
    const trainingStatus$ = this.trainingStatusControl.valueChanges.pipe(
      startWith('all'),
      tap(status => {
        if (~['completed', 'all'].indexOf(status)) {
          !~this.listConfig.displayedColumns.indexOf('date') &&
            this.listConfig.displayedColumns.push('date');
        } else {
          ~this.listConfig.displayedColumns.indexOf('date') &&
            this.listConfig.displayedColumns.splice(
              this.listConfig.displayedColumns.indexOf('date'),
              1
            );
        }

        if (~['cancelled'].indexOf(status)) {
          ~this.listConfig.displayedColumns.indexOf('completed') &&
            this.listConfig.displayedColumns.splice(
              this.listConfig.displayedColumns.indexOf('completed'),
              1
            );
        } else {
          !~this.listConfig.displayedColumns.indexOf('completed') &&
            this.listConfig.displayedColumns.push('completed');
        }
      })
    );
    const userName$ = fromEvent(this.filterByUser.nativeElement, 'input').pipe(
      startWith({target: {value: ''}}),
      map((control: any) => control.target.value)
    );

    combineLatest(userName$, trainingStatus$)
      .pipe(
        skip(1),
        debounceTime(300),
        takeWhile(() => this.aliveObservable)
      )
      .subscribe(([userName, trainingStatus]) => {
        const filterValue = this.listConfig.filter.value || {};
        this.listConfig.filter.next(Object.assign(filterValue, {trainingStatus, userName}));
      });
  }

  exportToCsv() {
    const {taskId} = this.data;
    this.data.daoService.exportUsersToCsv(taskId).subscribe(() => {
      // this.dialogRef.close();
    });
  }
}
