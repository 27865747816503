import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {SfConnectionsConfigInterface} from '../models/sf-connections.interface';
import {DocPackageInterface} from '../models/doc-package.interface';
import {Q9UserService} from '@q9elements/ui-core';

@Injectable({
  providedIn: 'root'
})
export class SfConnectionsStore {
  constructor(private q9UserService: Q9UserService) {}

  private selectedRowSubject = new BehaviorSubject<any>(undefined);
  private selectedRow$ = this.selectedRowSubject.asObservable().pipe(distinctUntilChanged());

  private sfConnectionsConfigSubject = new BehaviorSubject<SfConnectionsConfigInterface>(undefined);
  private sfConnectionsConfig$ = this.sfConnectionsConfigSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private needUpdateUserListSubject = new BehaviorSubject<any>(false);
  private needUpdateUserList$ = this.needUpdateUserListSubject.asObservable();

  private updateSfConnectionsListSubject = new BehaviorSubject<symbol>(undefined);
  private updateSfConnectionsList$ = this.updateSfConnectionsListSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private sfImplementationsListSubject = new BehaviorSubject<any>([]);
  private sfImplementationsList$ = this.sfImplementationsListSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private orgForSelectSubject = new BehaviorSubject<any>(undefined);
  private orgForSelect$ = this.orgForSelectSubject.asObservable().pipe(distinctUntilChanged());

  private orgProcessingSubject = new BehaviorSubject<any>({});
  private orgProcessing$ = this.orgProcessingSubject.asObservable().pipe(distinctUntilChanged());

  private updateRowInListSubject = new BehaviorSubject<any>(undefined);
  private updateRowInList$ = this.updateRowInListSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private orgsMessagesSubject = new BehaviorSubject<any>({});
  private orgsMessages$ = this.orgsMessagesSubject.asObservable().pipe(distinctUntilChanged());

  private docPackageSubject = new BehaviorSubject<DocPackageInterface>(undefined);
  private docPackage$ = this.docPackageSubject.asObservable().pipe(distinctUntilChanged());

  private needUpdateImportedVersionsSubject = new BehaviorSubject<boolean>(undefined);
  private needUpdateImportedVersions$ = this.needUpdateImportedVersionsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public syncSubscriptions: Set<any> = new Set<any>();

  set(name: string, stateValue: any) {
    this[`${name}Subject`].next(stateValue);
  }

  setSelectedRow(stateValue: any, type = 'org') {
    const teamId = this.q9UserService.getTeamId();
    const value = Object.assign({data: stateValue}, {type, teamId});
    this.selectedRowSubject.next(value);
  }

  getValue(name = 'selectedRow') {
    return name === 'selectedRow'
      ? this[`${name}Subject`].value && this[`${name}Subject`].value.data
      : this[`${name}Subject`].value;
  }

  getSubscription(name: string): Observable<any> {
    return this[name];
  }
}
