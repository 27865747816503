import {Injectable} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {
  Q9AttachmentDialogService,
  Q9DataTableAttachDialogComponent,
  Q9DataTableRecordsDialogComponent,
  Q9IntercomService
} from '@q9elements/ui-core';

@Injectable({providedIn: 'root'})
export class AttachmentDialogService extends Q9AttachmentDialogService {
  get intercomService() {
    return this.injector.get(Q9IntercomService);
  }

  getDataTableDialog(
    attachment?,
    params?,
    excludeObject?
  ): MatDialogRef<Q9DataTableAttachDialogComponent | Q9DataTableRecordsDialogComponent> {
    const component: any = attachment
      ? Q9DataTableRecordsDialogComponent
      : Q9DataTableAttachDialogComponent;
    const modalWidth = attachment ? 'auto' : '400px';
    return this.dialog.open(component, {
      width: modalWidth,
      disableClose: true,
      panelClass: ['q9-dialog', 'data-table-attachment-dialog'],
      autoFocus: params && params.allowToAddRecords,
      data: {
        attachment,
        params,
        excludeObject,
        intercom: this.intercomService,
        constant: 'add-data-table-record'
      }
    });
  }
}
