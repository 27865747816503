import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {SfConnectionsUtilService} from './sf-connections-util.service';
import {SfConnectionsStore} from './sf-connections-store.service';

@Injectable({
  providedIn: 'root'
})
export class SfResponseParseResolver {
  constructor(
    private sfConnectionsStore: SfConnectionsStore,
    private sfConnectionsUtilService: SfConnectionsUtilService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.sfConnectionsUtilService.parseSfCallbackQueryParams(route.queryParams);
  }
}
