import * as _ from 'lodash';

export class GdprLegalBasisStates {
  private states;

  constructor() {}

  get unassessed() {
    return this.states['unassessed'];
  }

  get legalObligation() {
    return this.states['legalObligation'];
  }

  get contract() {
    return this.states['contract'];
  }

  get vitalInterest() {
    return this.states['vitalInterest'];
  }

  get publicInterest() {
    return this.states['publicInterest'];
  }

  get legitimateInterest() {
    return this.states['legitimateInterest'];
  }

  get consent() {
    return this.states['consent'];
  }

  get noBasis() {
    return this.states['noBasis'];
  }

  setupGdprLegalBasisStates(translate) {
    this.states = {
      unassessed: {color: '#001489', label: translate.instant('REFERENCE_MODELS.UNASSESSED')},
      legalObligation: {
        color: '#b6bb2a',
        label: translate.instant('REFERENCE_MODELS.LEGAL_OBLIGATION')
      },
      contract: {color: '#0093b2', label: translate.instant('REFERENCE_MODELS.CONTRACT')},
      vitalInterest: {
        color: '#aa0061',
        label: translate.instant('REFERENCE_MODELS.VITAL_INTEREST')
      },
      publicInterest: {
        color: '#00843d',
        label: translate.instant('REFERENCE_MODELS.PUBLIC_INTEREST')
      },
      legitimateInterest: {
        color: '#c06200',
        label: translate.instant('REFERENCE_MODELS.LEGITIMATE_INTEREST')
      },
      consent: {color: '#772b98', label: translate.instant('REFERENCE_MODELS.CONSENT')},
      noBasis: {color: '#fa4616', label: translate.instant('REFERENCE_MODELS.NO_LEGAL_BASIS')}
    };
    return this;
  }

  getStates() {
    return this.states;
  }
}

export class GdprLegalBasisStatusChartConfig extends GdprLegalBasisStates {
  dataTable = [];
  chartType = 'PieChart';
  options;

  constructor() {
    super();
  }

  updateWithOptions(options) {
    this.options = _.merge(this.options, options);
    return this;
  }

  private prepareOptions() {
    this.options = {
      pieHole: 0.4,
      legend: {
        position: 'none',
        textStyle: {color: '#333333'}
      },
      enableInteractivity: false,
      tooltip: {
        trigger: 'focus',
        textStyle: {
          fontName: 'Source Sans Pro',
          fontSize: 10,
          color: '#333333'
        }
      },
      slices: [
        {color: this.unassessed.color},
        {color: this.legalObligation.color},
        {color: this.contract.color},
        {color: this.vitalInterest.color},
        {color: this.publicInterest.color},
        {color: this.legitimateInterest.color},
        {color: this.consent.color},
        {color: this.noBasis.color}
      ],
      chartArea: {
        left: 20,
        right: 20,
        width: '100%',
        height: '70%'
      },
      fontName: 'Source Sans Pro',
      pieSliceText: 'none'
    };

    return this;
  }

  private createTable(data) {
    this.dataTable = [
      ['Topping', 'Slices'],
      [this.unassessed.label, data.unassessed],
      [this.legalObligation.label, data.legalObligation],
      [this.contract.label, data.contract],
      [this.vitalInterest.label, data.vitalInterest],
      [this.publicInterest.label, data.publicInterest],
      [this.legitimateInterest.label, data.legitimateInterest],
      [this.consent.label, data.consent],
      [this.noBasis.label, data.noBasis]
    ];

    return this;
  }

  setupConfig(translate, data) {
    return this.setupGdprLegalBasisStates(translate)
      .prepareOptions()
      .createTable(data);
  }
}
