import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {retryWithBackoff} from '@q9elements/ui-core';
import {CookieService} from 'ngx-cookie-service';
import {tap} from 'rxjs/operators';

import {environment} from '../environments/environment';
import {Q9ListStore} from './_shared/components/q9-list/store.service';
import {SharedDialogsService} from './_shared/dialogs/shared-dialogs.service';
import {AuthService} from './services/auth/auth.service';
import {TeamUtilService} from './teams/services/team-util.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // private token: string;
  private main_app_url: string;
  private errorsToShow = new Set();

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private dialog: MatDialog,
    private injector: Injector,
    private teamUtilService: TeamUtilService
  ) {
    this.main_app_url = environment.MAIN_APP_URL;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = req.headers.get('Authorization');

    const sharedDialogService = this.injector.get(SharedDialogsService);
    const dialogRef = this.injector.get(MatDialog);
    const store = this.injector.get(Q9ListStore);

    let headers, authReq;
    if (token === 'no_token') {
      authReq = req.clone({headers: req.headers.delete('Authorization')});
    } else {
      headers = req.headers.set('Authorization', `token ${this.cookieService.get('auth-token')}`);

      if (typeof this.teamUtilService.getTeamIndex() === 'number') {
        headers = headers.set('el-team', `${this.teamUtilService.getTeamIndex()}`);
      }
      if (this.teamUtilService.getTeamId()) {
        headers = headers.set('el-team-id', this.teamUtilService.getTeamId());
      }

      authReq = req.clone({headers: headers});
    }

    return next.handle(authReq).pipe(
      retryWithBackoff(),
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        (response: any) => {
          if (response instanceof HttpErrorResponse) {
            if (response.status === 401) {
              return this.makeLogout();
            }

            let responseError: {status: string; error: string};

            try {
              responseError = JSON.parse(response.error);
            } catch (e) {
              responseError = response.error;
            }

            if (this.errorsToShow.has(responseError.status)) {
              return Promise.reject(response);
            }

            this.errorsToShow.add(responseError.status);

            let promise;

            switch (responseError.status) {
              case 'organization.not_found':
              case 'user_in_team.blocked':
              case 'team.err.is_not_pro':
              case 'err.rights.enterprise_not_active':
              case 'team_suspended':
                promise = showErrorModal(responseError, true).then(redirectToOrigin);
                break;
              case 'element_not_exists':
              case 'map.err.undergoing_training':
                promise = showErrorModal(responseError, true).then(refreshData);
                break;
              case 'user.err.blocked':
              case 'user.err.deleted_from_system':
                promise = showErrorModal(responseError).then(this.makeLogout.bind(this));
                break;
              default:
                promise = Promise.resolve();
            }

            promise.then(() => Promise.reject(response));
            promise.finally(() => this.errorsToShow.delete(responseError.status));

            return promise;
          }

          return Promise.reject(response);

          function refreshData() {
            store.set('filter', store.getValue('filter') || {});
          }

          function showErrorModal(responseError, dismissAll?: boolean) {
            return new Promise(resolve => {
              if (dismissAll) {
                dialogRef.closeAll();
              }
              return sharedDialogService
                .showAlertDialog(responseError.error)
                .afterClosed()
                .subscribe(resolve, resolve);
            });
          }

          function redirectToOrigin() {
            return (window.location.href = window.location.origin);
          }
        }
      )
    );
  }

  makeLogout() {
    const authService = this.injector.get(AuthService);

    return authService.logOut().subscribe(() => {
      const redirectUrl =
        window.location.pathname !== '/'
          ? './signin?redirect=' + encodeURIComponent(window.location.href)
          : window.location.origin;

      window.location.assign(redirectUrl);
    });
  }

  navigate() {
    return this.router.navigate(['signin']);
  }
}
