<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div mat-dialog-title>
    <div class="dialog-title">{{ 'TRAININGS.REMIND_COMPLETE_TRAINING_TITLE' | translate }}</div>
    <button mat-button mat-dialog-close tabindex="3" class="dialog-close">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div class="q9-modal-error" *ngIf="error">
    <div class="text">{{ error }}</div>
  </div>

  <mat-dialog-content class="content">
    <div class="content__form-message"> {{ remindMessage }} </div>

    <div class="spinner__wrapper-center" *ngIf="showSpinner">
      <mat-spinner strokeWidth="10" diameter="100"></mat-spinner>
    </div>
    <mat-form-field class="content__form-field" appearance="outline" [floatLabel]="'always'">
      <mat-label>{{ 'TRAININGS.REMIND_COMPLETE_TRAINING_LABEL' | translate }}</mat-label>
      <textarea
        matInput
        required
        cdkFocusInitial
        [errorStateMatcher]="matcher"
        formControlName="message"
        placeholder="Enter a message here"></textarea>
      <mat-error *ngIf="!messageErrorMinLength && !messageErrorMaxLength">
        {{ 'TRAININGS.REMIND_REQUIRED_MESSAGE_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="messageErrorMinLength">
        {{ 'TRAININGS.REMIND_MIN_MESSAGE_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="messageErrorMaxLength">
        {{ 'TRAININGS.REMIND_MAX_MESSAGE_ERROR ' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="q9-form-field q9-full-width"
      [floatLabel]="'always'"
      appearance="outline">
      <mat-label>{{ 'TRAININGS.REMIND_DATE_LABEL' | translate }}</mat-label>
      <input
        matInput
        [errorStateMatcher]="matcher"
        [dpDayPicker]="datePickerConfig"
        [placeholder]="'YYYY-MM-DD'"
        formControlName="requiredBy"
        readonly="true"
        theme="dp-material"
        #dateDirectivePicker="dpDayPicker"
        mode="daytime" />
      <div class="q9-date-picker-icon" matSuffix>
        <mat-icon svgIcon="datepicker_toggle"></mat-icon>
      </div>
      <mat-error> {{ 'TRAININGS.REMIND_DATE_LABEL' | translate }} </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="button-group">
      <button
        mat-button
        [disabled]="showSpinner"
        color="primary"
        class="q9-btn q9-btn-border"
        tabindex="1"
        mat-dialog-close>
        {{ 'TRAININGS.CANCEL_BTN' | translate }}
      </button>
      <button
        mat-raised-button
        [disabled]="showSpinner"
        class="q9-btn"
        type="submit"
        color="primary">
        {{ 'TRAININGS.REMIND_SEND_BTN' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</form>
