import {CommonModule} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

import {ChangeStateWarnData} from './change-state-warn-data';

@Component({
  selector: 'change-state-warn',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, MatDialogModule, MatButtonModule],
  templateUrl: 'change-state-warn.component.html'
})
export class ChangeStateWarnComponent {
  constructor(
    public dialogRef: MatDialogRef<ChangeStateWarnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeStateWarnData
  ) {}
}
