import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Q9GainSightService, Q9IntercomService, Q9UserService} from '@q9elements/ui-core';
import {get} from 'lodash';

import {AuthService} from '../../services/auth/auth.service';
import {FireStoreService} from '../../services/firestore/firestore.service';
import {getCurrentPath} from '../../util/routing.util';
import {TeamUtilService} from '../services/team-util.service';

@Injectable({providedIn: 'root'})
export class TeamIndexResolver {
  constructor(
    private authService: AuthService,
    private q9UserService: Q9UserService,
    private router: Router,
    private teamUtilService: TeamUtilService,
    private firestoreService: FireStoreService,
    private q9IntercomService: Q9IntercomService,
    private q9GainSightService: Q9GainSightService
  ) {}

  resolve(parent: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let teamIndex = get(parent, 'firstChild.firstChild.params.teamId', null);

    return new Promise((resolve, reject) => {
      const locationHref = window.location.href;

      const getTeamIndex = () => {
        if (teamIndex !== null && !isNaN(+teamIndex)) {
          this.teamUtilService.setTeamIndex(teamIndex);
        }

        this.teamUtilService.setTeamId('');

        this.authService
          .checkState(parent.data.userState, true)
          .then((user: any) => {
            const {
              teamIndex: defaultTeamIndex,
              team: {id: defaultTeamId}
            } = user.userInTeam;

            this.teamUtilService.setTeamId(defaultTeamId);
            this.q9IntercomService.bootIntercom(this.q9UserService.user);
            this.q9GainSightService.setupGainSight(this.q9UserService.user);
            this.teamUtilService.teamIdSubject.next(defaultTeamId);

            const teamIndexIsNan = teamIndex && isNaN(+teamIndex);

            if (teamIndexIsNan) {
              teamIndex = this.tryGetTeamIndexByTeamId(teamIndex, user) || defaultTeamIndex;

              this.modifyUrl(parent, teamIndex);
              return resolve(teamIndex);
            }

            if (!teamIndex) {
              this.teamUtilService.setTeamIndex(defaultTeamIndex);
              resolve(defaultTeamIndex);
            } else {
              resolve(+teamIndex);
            }
          })
          .catch((errorInfo = {}) => {
            const {fetchUserData, err} = errorInfo;

            if (!fetchUserData) {
              return reject(errorInfo);
            }

            if (err.skipResolver) {
              return;
            }

            let status = '';

            if (err.error) {
              status = err.error.status ? err.error.status : err.status;
            }

            if (status === 'organization.not_found') {
              /*
               * If error appeared - remove current teamId from url and from memory
               * and send request for user with default team
               */
              // $stateParams.teamId = _teamId = null;
              teamIndex = null;
              // delete route.params['teamId'];

              getTeamIndex();
            } else if (status === 'no_token') {
              /*
               * If no token in cookies
               * redirect to signin page
               */
              const needsRedirect = state.url;

              // trying to navigate on the task list
              if (/^\/tasks+/.test(needsRedirect)) {
                window.location.replace('./signin?redirect=' + encodeURIComponent(needsRedirect));
                return;
              }

              // trying to navigate on not default routes
              if (needsRedirect !== '/t') {
                window.location.replace('./signin?redirect=' + encodeURIComponent(locationHref));
              } else {
                this.router.navigate(['signin']);
              }
            } else {
              this.logOut();
            }
          });
      };

      getTeamIndex();
    });
  }

  tryGetTeamIndexByTeamId(teamIndex, user) {
    return user.teams.find(({team}) => team.id === teamIndex)?.teamIndex;
  }

  modifyUrl(route: ActivatedRouteSnapshot, teamIndex) {
    const pathArray = getCurrentPath(route);

    pathArray[1] = teamIndex;
    this.router.navigate(pathArray, {queryParams: route.queryParams, replaceUrl: true});
  }

  logOut() {
    this.authService.logOut().subscribe(() => this.router.navigate(['signin']));
  }
}
