import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';

import {KeysPipe} from '../../pipes/keys/keys.pipe';

@Component({
  selector: 'gdpr-stat-donut',
  standalone: true,
  templateUrl: './gdpr-stat-donut.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    Ng2GoogleChartsModule,
    KeysPipe
  ],
  styleUrls: ['./gdpr-stat-donut.component.scss']
})
export class GdprStatDonutComponent implements OnInit {
  gdprStates;
  gdprStatData;
  gdprStatusChartConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GdprStatDonutComponent>
  ) {}

  ngOnInit() {
    this.gdprStates = this.data.gdprStates;
    this.gdprStatData = this.data.gdprStatData;
    this.gdprStatusChartConfig = this.data.gdprStatusChartConfig;

    this.gdprStatusChartConfig.options.enableInteractivity = true;
    this.gdprStatusChartConfig.options.legend.textStyle.fontSize = 14;
    this.gdprStatusChartConfig.options.chartArea.height = '90%';
    this.gdprStatusChartConfig.options.chartArea.left = 0;
  }
}
