import {AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class Q9Validators {
  static required(control: UntypedFormControl): ValidationErrors | null {
    if (control.value && typeof control.value !== 'string') {
      return null;
    }

    const value = control.value ? control.value.trim() : '';
    return value.length ? null : {required: true};
  }

  static minLength(minLength: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const value = c.value ? c.value.trim() : null;

      return value && value.length < minLength
        ? {minlength: {requiredLength: minLength, actualLength: value.length}}
        : null;
    };
  }

  static maxLength(maxLength: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      const value = c.value ? c.value.trim() : null;

      return value && value.length > maxLength
        ? {maxlength: {requiredLength: maxLength, actualLength: value.length}}
        : null;
    };
  }

  static controlsMatch(a: string, b: string) {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlA = control.get(a).value;
      const controlB = control.get(b).value;
      const valid = String(controlA) === String(controlB);

      return valid ? null : {emailNotMatch: true};
    };
  }

  static isObject(control: AbstractControl): ValidationErrors | null {
    return typeof control.value === 'object' ? null : {isObject: true};
  }
}
