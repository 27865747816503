import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

import {
  Q9PermissionsService,
  Q9SnackBarService,
  Q9UserService,
  UserNameUtil
} from '@q9elements/ui-core';
import {TranslateService} from '@ngx-translate/core';

import {RefModelsSharedDaoService} from '../../../../services/referencemodels/ref-models-shared-dao.service';
import {filter, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ConfirmDialogComponent} from '../../../../dialogs/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'ref-model-user',
  templateUrl: './ref-model-user.component.html',
  styleUrls: ['./ref-model-user.component.scss']
})
export class RefModelUserComponent implements OnInit, OnChanges {
  @Input() user;
  @Input() refModel;

  @Output() callback: EventEmitter<any> = new EventEmitter<any>();

  currentUserId;
  currentUserTeam;

  userRoles = {
    view: this.translate.instant('USER.ROLE_VIEWER').toLowerCase(),
    edit: this.translate.instant('USER.ROLE_EDITOR').toLowerCase(),
    manage: this.translate.instant('USER.ROLE_MANAGER').toLowerCase()
  };
  userPermissionOptions = [
    {title: 'USER.PERMISSIONS_VIEW_ONLY', value: 'view'},
    {title: 'USER.PERMISSIONS_CAN_EDIT', value: 'edit'},
    {title: 'USER.PERMISSIONS_MANAGE', value: 'manage'}
  ];

  isLoading = false;
  isManager: boolean;
  processingChange = false;
  isExternalRefModel: any;

  constructor(
    private refOrgSharedDaoService: RefModelsSharedDaoService,
    private translate: TranslateService,
    private q9snackBarService: Q9SnackBarService,
    private q9PermissionsService: Q9PermissionsService,
    private dialog: MatDialog,
    private q9UserService: Q9UserService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    this.currentUserId = this.q9UserService.getUserId();
    this.currentUserTeam = this.q9UserService.getTeamId();
    this.isManager = this.refModel.role === 'manage';
    this.isExternalRefModel = this.refModel.external;
  }

  changeRefModelUserRole(option, cr) {
    const selectedRow = this.refModel;

    this.isLoading = true;
    const body = {user: cr.id, referenceModel: cr.refModelId, role: option.value};
    this.refOrgSharedDaoService.updateRefModelRights(cr.refModelId, cr.id, body).subscribe(
      controlRight => {
        const mutateRowAction = {data: selectedRow, action: 'mutate-row'};
        const changeRoleAction = {data: option.value, user: cr, action: 'change-role'};
        const roleData =
          controlRight.user.id === this.currentUserId ? mutateRowAction : changeRoleAction;

        this.callback.emit(roleData);
        const message = `${this.translate.instant('REFERENCE_MODELS.ROLE_CHANGED')} ${
          this.userRoles[option.value]
        }`;
        this.showToast(message);
      },
      failure => {
        this.isLoading = false;
        const {error} = failure;
        this.showToast(error);
        this.callback.emit({data: selectedRow});
      }
    );
  }

  isMe(cr) {
    return cr.id === this.currentUserId;
  }

  canChangeRole(user) {
    // todo: handle user.refModelRole
    return this.isManager && user.orgId === this.currentUserTeam;
  }

  isViewerInTeam(user) {
    return user.teamPermission.role === 'viewer';
  }

  deleteUserFromRefModel(cr) {
    const selectedRow = this.refModel;

    this.isLoading = true;

    this.refOrgSharedDaoService
      .checkUserAsStakeholderInRM(cr.refModelId, cr.id)
      .pipe(
        switchMap((isStakeholder: boolean) => {
          if (!isStakeholder || !this.q9PermissionsService.isProPlus) {
            return of(true);
          }
          const type = this.refModel.source === 'salesforce' ? 'Org' : 'Ref';

          const dialog = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            panelClass: ['q9-dialog'],
            data: {
              title: this.translate.instant('REFERENCE_MODELS.REMOVE_STAKEHOLDER_MODAL_TITLE'),
              text: this.translate.instant('REFERENCE_MODELS.REMOVE_STAKEHOLDER_MODAL_MESSAGE', {
                userName: UserNameUtil.getFullName(this.user),
                modelType: type
              }),
              confirm: this.translate.instant('REFERENCE_MODELS.REMOVE_STAKEHOLDER_MODAL_BTN')
            }
          });

          return dialog.afterClosed();
        }),
        filter(confirmed => {
          if (!confirmed) {
            this.isLoading = false;
            this.callback.emit({action: 'refresh-users'});
          }

          return confirmed;
        }),
        switchMap(() => this.refOrgSharedDaoService.deleteUserFromRefModel(cr.refModelId, cr.id))
      )
      .subscribe(
        res => {
          if (res.length > 0 && res[0].user === this.currentUserId) {
            let action;
            if (selectedRow.viewAll) {
              selectedRow.role = 'view';
              action = 'mutate-row';
            } else {
              action = 'remove-ref-model';
            }

            this.callback.emit({data: selectedRow, action: action});
          } else {
            this.callback.emit({data: selectedRow, action: 'remove-user', user: cr});
            this.showToast('REFERENCE_MODELS.REMOVED_USER');
          }
        },
        failure => {
          switch (failure.status) {
            case 'refmodel.err.rights.does_not_exist':
              this.showToast(failure.error);
              return this.callback.emit({action: 'refresh-filter'});
            case 'user.err.rmacr_not_found':
              this.showToast('REFERENCE_MODELS.REMOVED_USER');
              return this.callback.emit({data: selectedRow, action: 'remove-user', user: cr});
          }
          const {error} = failure;

          this.callback.emit({action: 'refresh-users'});
          this.isLoading = false;
          this.showToast(error);
        }
      );
  }

  receiveUpdatesChange({checked}, cr) {
    const body = {
      user: cr.id,
      referenceModel: cr.refModelId,
      sendEmailAboutChanges: checked
    };
    this.processingChange = true;
    this.refOrgSharedDaoService.updateRefModelRights(cr.refModelId, cr.id, body).subscribe(
      (result: any) => {
        if (result.hasOwnProperty('sendEmailAboutChanges')) {
          this.callback.emit({data: checked, user: cr, action: 'refresh-users'});
        }
      },
      () => {
        this.callback.emit({data: checked, user: cr, action: 'refresh-users'});
      }
    );
  }

  private showToast(message) {
    this.q9snackBarService.show(message);
  }
}
