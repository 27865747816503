import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';

import {mergeMap, skip, take} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {UserSettingsService} from '../services/user-settings.service';
import {TeamUtilService} from '../../teams/services/team-util.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsResolver {
  constructor(
    private userSettingsService: UserSettingsService,
    private teamUtilService: TeamUtilService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.teamUtilService.teamIdSubject.pipe(
      skip(1),
      take(1),
      mergeMap(() => this.userSettingsService.getDataFromFirestore())
    );
  }
}
